import { client } from './client'

let session = {
  isAuthenticated: function () {
    return JSON.parse(localStorage.getItem('authenticated')) || false
  },
  getToken: function () {
    return localStorage.getItem('token')
  },
  authenticate: function (token) {
    client.clearStore()
    localStorage.setItem('authenticated', true)
    localStorage.setItem('token', token)
  },
  signout: function (cb) {
    localStorage.removeItem('authenticated')
    localStorage.removeItem('token')
    client.clearStore()

    setTimeout(cb, 1)
  },
}

export default session
