import React, { useState, useMemo, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { Button, Select, MenuItem, Divider, Input } from '@material-ui/core'
import { useMutation, useLazyQuery, useQuery, gql } from '@apollo/client'
import { format, parseISO, differenceInHours } from 'date-fns'
import ResponsiveDrawer from '../components/drawer'
import queryString from 'query-string'
import { locationColor, icon } from '../components/icons'

const INVOICE = gql`
  query Invoice($id: Int!) {
    invoice(id: $id) {
      id
      total
      status
      checkNumber

      user {
        id
        name
      }
      tasks {
        id
      }
    }
  }
`

const TASKS = gql`
  query Tasks($filter: TasksFilter!) {
    tasks(filter: $filter) {
      id
      title
      date
      type
      pickupLocationType
      price
      invoice {
        id
      }
      driver {
        name
      }

      actions {
        id
        type
      }
    }
  }
`

const LOOKUPS = gql`
  query Lookups {
    drivers: users {
      id
      name
      type
      active
      tasks {
        id
      }
    }

    invoiceStatus: __type(name: "InvoiceStatus") {
      name
      values: enumValues {
        name
      }
    }
  }
`

const CREATE_INVOICE = gql`
  mutation CreateInvoice($input: CreateInvoiceInput!) {
    invoice: createInvoice(input: $input) {
      id
      total
      status
      checkNumber

      user {
        name
      }
      tasks {
        id
      }
    }
  }
`

const UPDATE_INVOICE = gql`
  mutation UpdateInvoice($id: Int!, $input: UpdateInvoiceInput!) {
    invoice: updateInvoice(id: $id, input: $input) {
      id
      total
      status
      checkNumber

      user {
        name
      }
      tasks {
        id
      }
    }
  }
`

const UPDATE_TASK = gql`
  mutation UpdateTask($id: Int!, $input: UpdateTaskInput!) {
    task: updateTask(id: $id, input: $input) {
      id
      invoice {
        id
      }
    }
  }
`

function useQueryParam() {
  const queryParams = useLocation().search

  const memoizedValue = useMemo(() => {
    let val = queryString.parse(queryParams)
    return { id: val.id ? +val.id : null }
  }, [queryParams])

  return memoizedValue
}

export const EditInvoicePage = () => {
  const queryparams = useQueryParam()
  const history = useHistory()

  // const history = useHistory()
  const params = useParams()
  let id = +params.id || +queryparams.id

  const creating = !params.id

  const [driver, setDriver] = useState()
  const [status, setStatus] = useState()
  const [checkNumber, setCheckNumber] = useState()
  const [tasksInInvoice, setTasksInInvoice] = useState([])

  const [getInvoice, { data: invoiceData, refetch: invoiceRefetch }] =
    useLazyQuery(INVOICE, {
      variables: { id: +id },
      fetchPolicy: 'cache-and-network',
    })

  const [getTasks, { data: taskData }] = useLazyQuery(TASKS, {
    variables: {
      filter: {
        driver_id: +(invoiceData?.invoice?.user?.id || driver),
        for_invoice_id: +invoiceData?.invoice?.id,
        status: 'COMPLETED',
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  let { data: lookups, loading: lookupsLoading } = useQuery(LOOKUPS)

  const [doEdit, { loading: editLoading }] = useMutation(
    creating ? CREATE_INVOICE : UPDATE_INVOICE
  )
  const [doTaskUpdate] = useMutation(UPDATE_TASK)
  // const [doUpdateSubTask] = useMutation(UPDATE_SUB_TASK)

  useEffect(() => {
    if (id) getInvoice()
  }, [id, getInvoice])

  useEffect(() => {
    if (invoiceData?.invoice?.user?.id || driver) {
      getTasks()
    }
  }, [invoiceData?.invoice?.user?.id, driver, getTasks])

  // useEffect(() => {
  //   if (taskData && taskData?.tasks) {
  //     let ids = taskData.tasks
  //       .filter((task) => task?.invoice?.id != null)
  //       .reduce((p, task) => {
  //         console.log(p, task)
  //         return [...p, task.invoice.id]
  //       }, [])
  //     setTasksInInvoice(ids)
  //   }
  // }, [taskData])

  let save = async (e) => {
    e.preventDefault()
    try {
      if (creating) {
        await doEdit({
          variables: {
            id: +id,
            input: {
              userId: driver,
              tasks: tasksInInvoice,
              status: status,
              checkNumber: checkNumber,
            },
          },
        })
      } else {
        await doEdit({
          variables: {
            id: +id,
            input: {
              tasks: tasksInInvoice,
              status: status || invoiceData.invoice.status,
              checkNumber: checkNumber || invoiceData.invoice.checkNumber,
            },
          },
        })
      }

      history.goBack()
      // history.replace({
      //   pathname: `/dashboard/tasks/${result.data.task.id}`
      // })
      // history.replace(`/dashboard/invoices`)
    } catch (error) {
      alert(error.message)
    }
  }

  let updateTask = async (id, checked) => {
    try {
      await doTaskUpdate({
        variables: {
          id: +id,
          input: { invoiceId: checked ? +invoiceData.invoice.id : null },
        },
      })
      await invoiceRefetch()
    } catch (error) {
      alert(error.message)
    }
  }

  let checkChanged = async (taskId, checked) => {
    if (creating) {
      if (checked) {
        setTasksInInvoice([...tasksInInvoice, +taskId])
      } else {
        setTasksInInvoice(tasksInInvoice.filter((t) => t !== +taskId))
      }
    } else {
      await updateTask(taskId, checked)
      // invoiceRefetch()
    }
  }

  return (
    <ResponsiveDrawer>
      <div className=' h-full flex flex-col space-y-4'>
        <h3>{creating ? 'New' : 'Edit'} Invoice</h3>

        <div className='flex flex-col flex-1'>
          <div className='flex flex-auto flex-col'>
            <div className='flex flex-row items-center space-x-4'>
              <div>Invoice for</div>
              <Select
                disabled={
                  (tasksInInvoice.length === 0 ? false : true) ||
                  !!invoiceData?.invoice?.user?.id
                }
                size='large'
                label='Assigned to'
                name='driver_id'
                value={invoiceData?.invoice?.user?.id || driver || ''}
                onChange={(e) => {
                  setDriver(e.target.value)
                }}
              >
                <MenuItem value={''} key={null}>
                  <div>None Selected</div>
                </MenuItem>
                {!lookupsLoading
                  ? lookups.drivers.map((driver) => (
                      <MenuItem
                        value={driver.id}
                        key={driver.id || driver.name}
                      >
                        {(
                          <div>
                            {icon(driver.type)} {driver.name}
                          </div>
                        ) || driver.name}
                      </MenuItem>
                    ))
                  : []}
              </Select>
            </div>
            <div className='flex flex-row items-center space-x-4'>
              <div>Status</div>
              <Select
                size='large'
                label='Status'
                name='status'
                value={status || invoiceData?.invoice?.status || 'UNPAID'}
                onChange={(e) => {
                  setStatus(e.target.value)
                }}
              >
                {!lookupsLoading
                  ? lookups.invoiceStatus.values.map((status) => (
                      <MenuItem value={status.name} key={status.name}>
                        {status.name}
                      </MenuItem>
                    ))
                  : []}
              </Select>
            </div>
            <div className='flex flex-row items-center space-x-4'>
              <div>Check Number</div>
              <Input
                size='large'
                label='Status'
                name='status'
                value={checkNumber || invoiceData?.invoice?.checkNumber || ''}
                onChange={(e) => {
                  setCheckNumber(e.target.value)
                }}
              />
            </div>
            <div className='flex flex-row items-center space-x-4'>
              <div>Total</div>
              <div>${(invoiceData?.invoice?.total || 0) / 100}</div>
            </div>

            {taskData && taskData.tasks.length > 0 ? (
              <div className='w-full md:flex md:flex-row flex-wrap m-1'>
                {taskData.tasks
                  .filter((task) => {
                    if (invoiceData?.invoice?.status === 'PAID')
                      return task.invoice != null
                    return true
                  })
                  .map((task) => (
                    <InvoiceTaskTile
                      paid={invoiceData?.invoice?.status === 'PAID'}
                      key={task.id}
                      task={task}
                      selected={
                        creating
                          ? tasksInInvoice.includes(task.id)
                          : task.invoice?.id === invoiceData?.invoice?.id
                      }
                      onClick={(e) => {
                        checkChanged(
                          task.id,
                          !(creating
                            ? tasksInInvoice.includes(task.id)
                            : task.invoice?.id === invoiceData?.invoice?.id)
                        )
                      }}
                    />
                  ))}
              </div>
            ) : (
              <div className='w-full flex-auto flex justify-center items-center text-3xl'>
                No completed tasks found
              </div>
            )}
          </div>
          <Button
            type='submit'
            variant='contained'
            disabled={editLoading}
            fullWidth
            onClick={save}
            style={{
              backgroundColor: 'green',
              color: 'white',
            }}
          >
            {creating ? 'Create' : 'Update'}
          </Button>
        </div>
      </div>
    </ResponsiveDrawer>
  )
}

const get_status = (task) => {
  const actions = task.actions?.map((action) => action?.type) || []

  if (actions.includes('COMPLETED')) return 'COMPLETED'
  if (actions.includes('NO_SHOW')) return 'NO SHOW'
  if (actions.includes('JOB_START')) return 'JOB STARTED'
  if (actions.includes('DRIVER_ARRIVED')) return 'DRIVER ARRIVED'
  if (actions.includes('FLIGHT_LANDED')) return 'FLIGHT LANDED'
  if (task.accepted) return 'ACCEPTED'
  if (task.driver && !task.accepted) return 'PENDING'
  if (!task.driver) return 'UNASSIGNED'

  return ''
}

const InvoiceTaskTile = ({ task, selected, onClick, paid }) => {
  const date = parseISO(task.date)

  //let actions = task.actions.map((action) => action.type)

  //const completed = actions.includes('COMPLETED') || actions.includes('NO_SHOW')

  const status = get_status(task)

  return (
    <div
      className='flex flex-col items-stretch border-4  shadow rounded-lg overflow-hidden bg-white m-1'
      style={
        ['DRIVER ARRIVED'].includes(status) &&
        differenceInHours(date, Date.now()) < -8
          ? { borderColor: 'orange' }
          : ['PENDING', 'UNASSIGNED'].includes(status) &&
            differenceInHours(date, Date.now()) < 24
          ? { borderColor: 'red' }
          : undefined
      }
    >
      <div
        className='flex items-center text-white h-11'
        style={{
          backgroundColor: locationColor(task.pickupLocationType),
        }}
      >
        <div className='text-white font-bold flex flex-1 justify-center items-center'>
          {task.type}
        </div>
      </div>

      <div className='p-2 space-y-2'>
        <div className='flex justify-between'>
          <div className='font-bold'>
            {format(parseISO(task.date), 'h:mm a, dd EEE, MMM yy')}
          </div>
          <div>{task.flightNumber}</div>
          {!!task.passengerCount && <div>{task.passengerCount} pax</div>}
        </div>

        {task.pickupLocation && task.dropoffLocation && (
          <div className='flex justify-between flex-wrap'>
            <div
              className='flex justify-center items-center px-3 text-sm rounded-full text-center whitespace-normal text-white bg-gray-200'
              style={{
                backgroundColor: locationColor(task.pickupLocationType),
              }}
            >
              {task.pickupLocation}
            </div>
            <span className='flex justify-center items-center'>to</span>
            <div className='flex justify-center items-center px-3 text-sm rounded-full text-center whitespace-normal text-black bg-gray-200'>
              {task.dropoffLocation}
            </div>
          </div>
        )}

        <div className='flex justify-between items-center flex-wrap'>
          <div>{task.title}</div>

          <div className='flex items-center'>
            {task.note?.length > 0 && icon('note', 'note')}
            {(task.features || []).map((feature) =>
              icon(feature, feature?.toString())
            )}
          </div>
        </div>
      </div>

      <div className='flex flex-col justify-end'>
        <Divider />
        <div className='flex justify-between items-center px-2'>
          <div className='text-center'>{status}</div>
        </div>
      </div>
      <div className='flex flex-col justify-end'>
        <Divider />
        <div className='flex justify-evenly items-center'>
          ${(task.price || 0) / 100}
          {!paid && (
            <Button onClick={onClick}>{selected ? 'Remove' : 'Add'}</Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default EditInvoicePage
