import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Button, Grid } from '@material-ui/core'
import { useMutation, useQuery, useLazyQuery, gql } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { pick } from 'lodash'
import ResponsiveDrawer from '../components/drawer'
import { FormFieldBuilder } from '../components/formFieldBuilder'

const formFields = [
  {
    name: 'type',
    type: 'dropdown',
    required: true,
    md: 12,
  },
  { name: 'name', label: 'Full Name', required: true },
  { name: 'username', required: true },
  { name: 'password' },
  { name: 'active', type: 'boolean' },
  { name: 'email' },
  { name: 'phoneNumber', label: 'Phone Number' },
  { name: 'address', for: ['DRIVER'] },
  { name: 'baseLocation', label: 'Base Location', for: ['DRIVER'] },
  { name: 'vehicleType', label: 'Vehicle Type', for: ['DRIVER'] },
  {
    name: 'vehicleDescription',
    label: 'Vehicle Description',
    for: ['DRIVER'],
  },
  {
    name: 'vehicleCapacity',
    type: 'number',
    label: 'Vehicle Capacity',
    for: ['DRIVER'],
  },
  {
    name: 'vehicleYear',
    type: 'number',
    label: 'Vehicle Year',
    for: ['DRIVER'],
  },
  {
    name: 'vehicleRegistrationNumber',
    label: 'Vehicle Registration Number',
    for: ['DRIVER'],
  },
  { name: 'insuranceCompany', label: 'Insurance Company', for: ['DRIVER'] },
  { name: 'note', label: 'Notes', type: 'textarea', md: 12 },
]

const fieldsToRetrieve = formFields.reduce((p, x) => {
  if (['password'].includes(x.name)) return p
  return `${p} ${x.name}`
}, 'id')

const TYPES = gql`
  query Types {
    types: __type(name: "UserType") {
      name
      values: enumValues {
        name
      }
    }
  }
`

const USER = gql`
  query User($id: Int!) {
    user(id: $id) {
      ${fieldsToRetrieve}
    }
  }
`

const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    user: createUser(input: $input) {
      ${fieldsToRetrieve}
    }
  }
`

const UPDATE_USER = gql`
  mutation UpdateUser($id: Int!, $input: UpdateUserInput!) {
    user: updateUser(id: $id, input: $input) {
      ${fieldsToRetrieve}
    }
  }
`

// function customComparator (a, b) {
//   console.log(a, b)
//   return (
//     _.difference(_.keys(a), _.keys(b)).length === 0 &&
//     _.difference(_.values(a), _.values(b)).length === 0
//   )
// }

export const EditUserPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams()
  const creating = !id

  let defaultValues = formFields.reduce((p, x) => {
    let value = ''
    if (x.type === 'number') value = 0
    if (x.type === 'boolean') value = true
    p[x.name] = value
    return p
  }, {})

  const [fields, setFields] = useState(defaultValues)

  let { data: typesData, loading: typesLoading } = useQuery(TYPES)

  const [getUser, { data: userData }] = useLazyQuery(USER, {
    variables: { id: +id },
  })

  const [doEdit, { loading: editLoading }] = useMutation(
    creating ? CREATE_USER : UPDATE_USER
  )

  React.useEffect(() => {
    if (id) getUser()
  }, [id, getUser])
  React.useEffect(() => {
    if (userData) {
      setFields(userData.user)
    }
  }, [userData, setFields])

  const updateFields = ({ name, value }) => {
    setFields((previousFields) => {
      return {
        ...previousFields,
        [name]: value,
      }
    })
  }

  let edit = async (e) => {
    e.preventDefault()
    try {
      // console.log(
      //   'test',
      //   _.differenceWith(fields, defaultValues, customComparator)
      // )
      await doEdit({
        variables: {
          id: +id,
          input: pick(
            fields,
            formFields.map((x) => x.name)
          ),
        },
      })

      history.goBack()
      // history.replace({
      //   pathname: `/dashboard/users/${result.data.user.id}`
      // })
    } catch (error) {
      // alert(error.message)
    }
  }

  let typeField = formFields.find((field) => field.name === 'type')
  if (typeField) {
    typeField.options =
      !typesLoading &&
      typesData.types.values.map((type) => ({
        value: type.name,
        name: type.name,
      }))
  }

  let passwordField = formFields.find((field) => field.name === 'password')
  if (passwordField) {
    passwordField.required = creating
  }

  return (
    <ResponsiveDrawer>
      <Grid style={{ padding: 8 }}>
        <h3>{creating ? 'New' : 'Edit'} User</h3>

        <Grid container>
          <Grid
            xs={12}
            container
            item
            component='form'
            onSubmit={edit}
            justify='flex-start'
            direction='row'
            spacing={2}
            className={classes.form}
          >
            {formFields
              .filter((field) => {
                if (!field.for) return true
                if (field.for && field.for.includes(fields.type)) return true
                return false
              })
              .map((field) => (
                <FormFieldBuilder
                  field={field}
                  onChange={updateFields}
                  value={fields[field.name]}
                  key={field.name}
                />
              ))}

            <Grid item xs={12} md={12}>
              <Button
                type='submit'
                variant='contained'
                disabled={editLoading}
                fullWidth
              >
                {creating ? 'Create' : 'Update'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ResponsiveDrawer>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    overflowX: 'auto',
  },
  form: {
    height: '100%',
  },
}))

export default EditUserPage
