import React from 'react'
import { Divider, Button, MenuItem, Menu, IconButton } from '@material-ui/core'
import { format, parseISO, differenceInHours } from 'date-fns'
import { locationColor, icon } from '../components/icons'
import { FormFieldBuilder } from '../components/formFieldBuilder'
import { gql, useMutation } from '@apollo/client'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { Flag, InfoOutlined } from '@material-ui/icons'

const UPDATE_TASK = gql`
  mutation UpdateTask($id: Int!, $input: UpdateTaskInput!) {
    task: updateTask(id: $id, input: $input) {
      id
      flagged
      deleted
      accepted
      driver {
        __typename
        id
        name
      }
      dispatcher {
        __typename
        id
        name
      }
    }
  }
`

const CREATE_TASK_ACTION = gql`
  mutation CreateTaskAction($input: CreateTaskActionInput!) {
    taskAction: createTaskAction(input: $input) {
      id
      type
      user {
        id
      }
      task {
        id
        actions {
          id
          type
          user {
            id
          }
        }
      }
    }
  }
`

const get_status = (task) => {
  const actions = task.actions?.map((action) => action?.type) || []

  if (actions.includes('COMPLETED')) return 'COMPLETED'
  if (actions.includes('NO_SHOW')) return 'NO SHOW'
  if (actions.includes('JOB_START')) return 'JOB STARTED'
  if (actions.includes('DRIVER_ARRIVED')) return 'DRIVER ARRIVED'
  if (actions.includes('FLIGHT_LANDED')) return 'FLIGHT LANDED'
  if (task.accepted) return 'ACCEPTED'
  if (task.driver && !task.accepted) return 'PENDING'
  if (!task.driver) return 'UNASSIGNED'

  return ''
}

export const TaskTile = ({ task, drivers, open, edit, audit }) => {
  const date = parseISO(task.date)
  const [doUpdate] = useMutation(UPDATE_TASK, {
    //refetchQueries: ['TasksData'],
  })
  const [doAction] = useMutation(CREATE_TASK_ACTION, {
    //refetchQueries: ['TasksData'],
  })
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  let actions = task.actions.map((action) => action.type)

  const completed = actions.includes('COMPLETED') || actions.includes('NO_SHOW')

  const status = get_status(task)

  return (
    <div
      className='flex flex-col items-stretch border-4  shadow rounded-lg overflow-hidden bg-white'
      style={
        ['DRIVER ARRIVED'].includes(status) &&
        differenceInHours(date, Date.now()) < -8
          ? { borderColor: 'orange' }
          : ['PENDING', 'UNASSIGNED'].includes(status) &&
            differenceInHours(date, Date.now()) < 24
          ? { borderColor: 'red' }
          : undefined
      }
    >
      <div
        className='flex items-center text-white h-11'
        style={{
          backgroundColor: locationColor(task.pickupLocationType),
        }}
      >
        <div className='flex justify-center items-center text-red-500 w-10'>
          {task.flagged && <Flag />}
        </div>
        <div className='text-white font-bold flex flex-1 ml-7  items-center'>
          <IconButton
            aria-label='audit'
            aria-haspopup='true'
            style={{ color: 'white' }}
            onClick={audit}
          >
            <InfoOutlined />
          </IconButton>
          {task.type}
        </div>
        <div>
          <IconButton
            aria-label='more'
            aria-controls='long-menu'
            aria-haspopup='true'
            style={{ color: 'white' }}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id='long-menu'
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {!completed && (
              <MenuItem
                onClick={(e) => {
                  try {
                    doAction({
                      variables: {
                        input: {
                          taskId: +task.id,
                          type: 'COMPLETED',
                        },
                      },
                    })
                  } catch (error) {
                    alert(error.message)
                  }

                  handleClose(e)
                }}
              >
                Complete
              </MenuItem>
            )}
            <MenuItem
              onClick={async (e) => {
                try {
                  doUpdate({
                    variables: {
                      id: +task.id,
                      input: { flagged: !task.flagged },
                    },
                  })
                } catch (error) {
                  alert(error.message)
                }

                handleClose(e)
              }}
            >
              {task.flagged ? 'Unflag' : 'Flag'}
            </MenuItem>

            <MenuItem
              onClick={async (e) => {
                try {
                  doUpdate({
                    variables: {
                      id: +task.id,
                      input: { deleted: !task.deleted },
                    },
                  })
                } catch (error) {
                  alert(error.message)
                }

                handleClose(e)
              }}
            >
              {task.deleted ? 'Restore' : 'Delete'}
            </MenuItem>
          </Menu>
        </div>
      </div>

      <div className='p-2 space-y-2'>
        <div className='flex justify-between'>
          <div className='font-bold'>
            {format(parseISO(task.date), 'h:mm a')}
          </div>
          <div>{task.flightNumber}</div>
          {!!task.passengerCount && <div>{task.passengerCount} pax</div>}
        </div>

        {task.pickupLocation && task.dropoffLocation && (
          <div className='flex justify-between flex-wrap'>
            <div
              className='flex justify-center items-center px-3 text-sm rounded-full text-center whitespace-normal text-white bg-gray-200'
              style={{
                backgroundColor: locationColor(task.pickupLocationType),
              }}
            >
              {task.pickupLocation}
            </div>
            <span className='flex justify-center items-center'>to</span>
            <div className='flex justify-center items-center px-3 text-sm rounded-full text-center whitespace-normal text-black bg-gray-200'>
              {task.dropoffLocation}
            </div>
          </div>
        )}

        <div className='flex justify-between items-center flex-wrap'>
          <div>{task.title}</div>

          <div className='flex items-center'>
            {task.note?.length > 0 && icon('note', 'note')}
            {(task.features || []).map((feature) =>
              icon(feature, feature?.toString())
            )}
          </div>
        </div>
      </div>

      <div className='flex flex-col justify-end'>
        <Divider />
        <div className='flex justify-between items-center px-2 space-x-2'>
          {['COMPLETED', 'NO SHOW'].includes(status) ? (
            <div className='flex flex-col'>
              <div className='text-xs text-gray-500'>Assigned to</div>
              <div>{task.driver?.name || 'No one'}</div>
            </div>
          ) : (
            <FormFieldBuilder
              field={{
                xs: 12,
                md: 12,
                name: 'driver_id',
                label: 'Assigned to',
                type: 'dropdown',
                options: !drivers
                  ? [{ value: null, name: 'None' }]
                  : (drivers || [])
                      .filter((user) => user.active)
                      .map((driver) => {
                        return {
                          value: driver?.id,
                          name: driver?.name,
                        }
                      })
                      .concat([{ value: null, name: 'None' }]),
              }}
              onChange={async (val) => {
                try {
                  await doUpdate({
                    variables: {
                      id: +task.id,
                      input: { driverId: val.value },
                    },
                  })
                } catch (error) {
                  alert(error.message)
                }
              }}
              value={task.driver?.id}
            />
          )}
          <div className='text-center'>{status}</div>
        </div>
        <div className='w-full px-2'>
          {['COMPLETED', 'NO SHOW'].includes(status) ? (
            <div className='flex flex-col'>
              <div className='text-xs text-gray-500'>Dispatcher</div>
              <div>{task.dispatcher?.name || 'No one'}</div>
            </div>
          ) : (
            <FormFieldBuilder
              field={{
                xs: 12,
                md: 12,
                name: 'dispatcher_id',
                label: 'Dispatcher',
                type: 'dropdown',
                options: !drivers
                  ? [{ value: null, name: 'None' }]
                  : (drivers || [])
                      .filter((user) => user.active)
                      .map((driver) => {
                        return {
                          value: driver?.id,
                          name: driver?.name,
                        }
                      })
                      .concat([{ value: null, name: 'None' }]),
              }}
              onChange={async (val) => {
                try {
                  await doUpdate({
                    variables: {
                      id: +task.id,
                      input: { dispatcherId: val.value },
                    },
                  })
                } catch (error) {
                  alert(error.message)
                }
              }}
              value={task.dispatcher?.id}
            />
          )}
        </div>
      </div>
      <div className='flex flex-col justify-end'>
        <Divider />
        <div className='flex justify-evenly'>
          <Button onClick={open}>View</Button>
          <Button onClick={edit}>Edit</Button>
          {/*<Button component={Link} to={`/dashboard/tasks/${task.id}/edit`}>
            Edit
          </Button>*/}
        </div>
      </div>
    </div>
  )
}
