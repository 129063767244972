import React from 'react'
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  FormHelperText,
  TextareaAutosize,
  FormLabel,
  Checkbox,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
} from '@material-ui/pickers'
import Autocomplete from '@material-ui/lab/Autocomplete'

export const FormFieldBuilder = ({ field, onChange, value }) => {
  const classes = useStyles()

  const label = field.label || field.name[0].toUpperCase() + field.name.slice(1)

  if (field.type === 'date') {
    return (
      <Grid item xs={field.xs || 12} md={field.md || 6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            size='small'
            fullWidth
            InputLabelProps={{
              shrink: field.shrink || (!!value && value !== 0),
            }}
            // inputVariant='outlined'
            required={field.required}
            variant='inline'
            format='dd/MM/yyyy'
            id={`${field.name}-date-picker`}
            label={label}
            value={value}
            onChange={(date) => {
              let val = {
                name: field.name,
                value: date.toISOString(),
              }
              onChange(val)
            }}
            // KeyboardButtonProps={{
            //   'aria-label': 'change date'
            // }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    )
  }

  if (field.type === 'time') {
    return (
      <Grid item xs={field.xs || 12} md={field.md || 6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TimePicker
            size='small'
            fullWidth
            InputLabelProps={{
              shrink: !!value && value !== 0,
            }}
            // inputVariant='outlined'
            variant='inline'
            required={field.required}
            mask='__:__ _M'
            id={`${field.name}-time-picker`}
            label={label}
            value={value}
            onChange={(date) => {
              let val = {
                name: field.name,
                value: date.toISOString(),
              }
              onChange(val)
            }}
            // KeyboardButtonProps={{
            //   'aria-label': 'change date'
            // }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    )
  }

  if (field.type === 'dropdown') {
    return (
      <Grid item xs={field.xs || 12} md={field.md || 6}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel
            htmlFor={`${field.name}-select`}
            required={field.required}
            shrink={!!value}
          >
            {label}
          </InputLabel>
          <Select
            size='small'
            label={label}
            name={field.name}
            value={value || ''}
            onChange={(e) => {
              let val = {
                name: e.target.name,
                value: e.target.value,
              }
              onChange(val)
            }}
            inputProps={{
              id: `${field.name}-select`,
            }}
          >
            {field.options &&
              field.options.length &&
              field.options.map((option) => (
                <MenuItem
                  value={option.value}
                  key={option.value || option.name}
                >
                  {option.display || option.name}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{field.helper || ' '}</FormHelperText>
        </FormControl>
      </Grid>
    )
  }

  if (field.type === 'autocomplete-dropdown') {
    return (
      <Grid item xs={field.xs || 12} md={field.md || 6}>
        <FormControl className={classes.formControl} fullWidth>
          <Autocomplete
            id={`${field.name}`}
            options={field.options}
            getOptionLabel={(option) => {
              return option.value
            }}
            value={value}
            onChange={(e, newValue) => {
              let val = {
                name: e.target.name,
                value: newValue.value,
              }
              onChange(val)
            }}
            renderInput={(params) => {
              return <TextField {...params} label={label} fullWidth />
            }}
          />
          {/* <InputLabel
            htmlFor={`${field.name}-select`}
            required={field.required}
            shrink={!!value}
          >
            {label}
          </InputLabel>
          <Select
            size='small'
            label={label}
            name={field.name}
            value={value}
            onChange={e => {
              let val = { name: e.target.name, value: e.target.value }
              onChange(val)
            }}
            inputProps={{
              id: `${field.name}-select`
            }}
          >
            {field.options &&
              field.options.length &&
              field.options.map(option => (
                <MenuItem value={option.value} key={option.value}>
                  {option.display || option.name}
                </MenuItem>
              ))}
          </Select> */}
          <FormHelperText>{field.helper || ' '}</FormHelperText>
        </FormControl>
      </Grid>
    )
  }

  if (field.type === 'textarea') {
    return (
      <Grid item xs={field.xs || 12} md={field.md || 6}>
        <FormControl className={classes.formControl} fullWidth>
          <FormLabel>
            {field.label || field.name[0].toUpperCase() + field.name.slice(1)}
          </FormLabel>
          <TextareaAutosize
            className='border border-black rounded-md p-1'
            minRows={10}
            name={field.name}
            value={value || ''}
            required={field.required}
            onChange={(e) => {
              let val = {
                name: e.target.name,
                value: e.target.value,
              }
              onChange(val)
            }}
          />
          <FormHelperText>{field.helper || ' '}</FormHelperText>
        </FormControl>
      </Grid>
    )
  }

  if (field.type === 'boolean') {
    return (
      <div
        className='flex flex-row p-2'
        // item
        // xs={field.xs || 12}
        // md={field.md || 6}
      >
        <FormControl className={classes.formControl}>
          <FormLabel>
            {field.label || field.name[0].toUpperCase() + field.name.slice(1)}
          </FormLabel>
          <Checkbox
            size='small'
            checked={value}
            onChange={(e) => {
              console.dir(e.target.checked)
              let val = {
                name: field.name,
                value: e.target.checked,
              }
              onChange(val)
            }}
            value={field.name}
            inputProps={{
              'aria-label': 'checkbox',
            }}
          />
          <FormHelperText>{field.helper || ' '}</FormHelperText>
        </FormControl>
      </div>
    )
  }
  if (field.type === 'multiselect') {
    return (
      <Grid item xs={field.xs || 12} md={field.md || 6}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel
            htmlFor={`${field.name}-select`}
            required={field.required}
            shrink={!!value}
          >
            {label}
          </InputLabel>
          <Select
            size='small'
            multiple
            label={label}
            name={field.name}
            value={value || []}
            onChange={(e) => {
              let val = {
                name: e.target.name,
                value: e.target.value || [],
              }
              onChange(val)
            }}
            renderValue={field.renderValue}
            inputProps={{
              id: `${field.name}-select`,
            }}
          >
            {field.options &&
              field.options.length &&
              field.options.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {option.display || option.name}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{field.helper || ' '}</FormHelperText>
        </FormControl>
      </Grid>
    )
  }

  return (
    <Grid item xs={field.xs || 12} md={field.md || 6} component={FormControl}>
      <TextField
        InputLabelProps={{
          shrink:
            (!field.type && !!value) ||
            (field.type === 'number' && value > -1) ||
            field.type === 'date' ||
            field.type === 'time',
        }}
        label={field.label || field.name[0].toUpperCase() + field.name.slice(1)}
        type={field.type || 'text'}
        name={field.name}
        value={value || ''}
        min={0}
        size='small'
        required={field.required}
        // variant='outlined'
        onChange={(e) => {
          let val = { name: e.target.name, value: e.target.value }
          if (field.type === 'number') {
            val.value = +val.value
          }
          onChange(val)
        }}
        helperText={field.helper || ' '}
      />
    </Grid>
  )
}

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 70,
  },
}))
