import React, { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import ResponsiveDrawer from '../components/drawer'
import { Button, Dialog } from '@material-ui/core'

import { pickBy, identity, groupBy } from 'lodash'
import { gql, useQuery, useMutation, useSubscription } from '@apollo/client'
import { FormFieldBuilder } from '../components/formFieldBuilder'
import { format, parseISO } from 'date-fns'
import { icon } from '../components/icons'

import queryString from 'query-string'
import { TaskTile } from './taskTile'
import { TaskPage } from './task'

import { getStartToken } from '../firebaseInit'
import Loading from '../components/loading'
import Message from '../components/message'
import EditTaskModal from './editTaskModal'
import InfiniteScroll from 'react-infinite-scroller'
import AuditPage from './audit'

const taskfields = `
  id
  title
  date
  type
  flightNumber
  pickupLocation
  pickupLocationType
  dropoffLocation
  passengerCount
  accepted
  features
  private
  internalNote
  note
  flagged
  deleted

  driver {
    id
    name
  }

  dispatcher {
    id
    name
  }


  actions {
    id
    type
    user {
      id
    }
  }
`

const TASKS = gql`
  query TasksData($filter: TasksFilter, $pagination: Pagination) {
    taskList(filter: $filter, pagination: $pagination) {
      entries {
         ${taskfields}
      }
      metadata {
        after
        before
        limit
        totalCount
      }
    }
  }
`

const UPDATE_PUSH_TOKEN = gql`
  mutation UpdatePushToken($token: String!) {
    updateToken(token: $token) {
      message
    }
  }
`

const TASKS_SUBSCRIPTION = gql`
  subscription TasksChanged {
    tasksChanged {
      ${taskfields}
    }
  }
`

const LOOKUPS = gql`
  query Lookups {
    drivers: users {
      id
      name
      type
      active
    }

    dispatchers: users(filter: { type: DISPATCHER }) {
      id
      name
      type
      active
    }

    taskTypes: __type(name: "TaskType") {
      name
      values: enumValues {
        name
      }
    }

    features: __type(name: "Features") {
      name
      values: enumValues {
        name
      }
    }

    dateRange: __type(name: "DateRange") {
      name
      values: enumValues {
        name
      }
    }

    pickupLocationType: __type(name: "PickupLocationType") {
      name
      values: enumValues {
        name
      }
    }
  }
`

// const UPDATE_TASK = gql`
//   mutation UpdateTask($id: Int!, $input: UpdateTaskInput!) {
//     task: updateTask(id: $id, input: $input) {
//       id
//       driver {
//         __typename
//         id
//         name
//       }
//     }
//   }
// `

function useQueryParam() {
  const queryParams = useLocation().search

  const memoizedValue = useMemo(() => {
    let val = queryString.parse(queryParams)
    return {
      date: null,
      ...val,
      driverId: val.driverId ? +val.driverId : null,
      dispatcherId: val.dispatcherId ? +val.dispatcherId : null,
    }
  }, [queryParams])

  return memoizedValue
}

export const TasksPage = () => {
  let queryparams = useQueryParam()
  let {
    loading: tasksLoading,
    error,
    data,
    refetch,
    fetchMore,
  } = useQuery(TASKS)
  const { loading: lookupsLoading, data: lookups } = useQuery(LOOKUPS)

  data = {
    tasks: data?.taskList?.entries || [],
    users: [],
    metadata: data?.taskList?.metadata || {},
  }
  const [filter, setFilter] = useState({
    date: null,
    ...queryparams,
  })

  const [updateToken] = useMutation(UPDATE_PUSH_TOKEN)

  useSubscription(TASKS_SUBSCRIPTION)

  const [showTaskId, showTask] = useState(null)
  const [auditTaskId, showAudit] = useState(null)
  const [editTask, setEditTask] = useState(null)

  useEffect(() => {
    let fun = async () => {
      try {
        let token = await getStartToken()
        await updateToken({ variables: { token: token } })
      } catch (error) {
        console.log(error)
      }
    }

    fun()
  }, [updateToken])

  useEffect(() => {
    setFilter(queryparams)
  }, [queryparams])

  useEffect(() => {
    refetch({ filter: pickBy(filter, identity) })
  }, [filter, refetch])

  const updateFilter = (e) => {
    const { name, value } = e
    setFilter((previousFilter) => {
      return {
        ...previousFilter,
        [name]: value,
      }
    })
  }

  if ((tasksLoading || lookupsLoading) && !lookups) {
    return (
      <ResponsiveDrawer>
        <Loading />
      </ResponsiveDrawer>
    )
  }

  let taskGroup = groupBy(data.tasks, (task) =>
    format(parseISO(task.date), 'dd EEEE, MMMM yyy', {
      timeZone: 'America/La_Paz',
    })
  )

  let modals = (
    <>
      {!!showTaskId && (
        <div
          className='fixed z-[5000] flex h-screen w-screen bg-opacity-75 bg-black top-0 right-0'
          style={{ display: showTaskId ? 'block' : 'none' }}
        >
          <div className='flex items-center justify-center h-full'>
            <div
              className='m-8 w-auto bg-white'
              onKeyDown={(e) => {
                if (e.key === 'Escape') showTask(null)
              }}
            >
              {showTaskId && <TaskPage taskId={showTaskId} />}
            </div>
          </div>

          <div className='absolute bottom-5 right-5 p-4 mx-auto'>
            <button
              className='bg-white rounded-lg p-2 text-gray-800 font-bold hover:bg-gray-300 focus:outline-none focus:bg-gray-300'
              onClick={() => showTask(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      {!!auditTaskId && (
        <div
          className='fixed z-[5000] flex h-screen w-screen bg-opacity-75 bg-black top-0 right-0'
          style={{ display: auditTaskId ? 'block' : 'none' }}
        >
          <div className='flex items-center justify-center h-full'>
            <div
              className='m-8 w-auto bg-white'
              onKeyDown={(e) => {
                if (e.key === 'Escape') showTask(null)
              }}
            >
              {auditTaskId && <AuditPage taskId={auditTaskId} />}
            </div>
          </div>

          <div className='absolute bottom-5 right-5 p-4 mx-auto'>
            <button
              className='bg-white rounded-lg p-2 text-gray-800 font-bold hover:bg-gray-300 focus:outline-none focus:bg-gray-300'
              onClick={() => showAudit(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      {!!editTask && (
        <Dialog
          maxWidth='md'
          onClose={(_event, reason) => {
            if (reason !== 'backdropClick') {
              setEditTask(null)
            }
          }}
          open={!!editTask}
        >
          {editTask && (
            <EditTaskModal
              task={editTask}
              close={() => setEditTask(null)}
              copy={() => {
                setEditTask(null)
                setTimeout(() => setEditTask({ ...editTask, id: null }), 400)
              }}
            />
          )}

          {/*<DialogActions>
            <Button color='primary' onClick={() => editTask(null)}>
              Cancel
            </Button>
          </DialogActions>*/}
        </Dialog>
      )}
    </>
  )

  if (tasksLoading && data?.tasks && !data.tasks.length)
    return (
      <ResponsiveDrawer>
        <Loading />
      </ResponsiveDrawer>
    )

  if (!tasksLoading && error)
    return (
      <>
        <ResponsiveDrawer>
          <div className='h-screen w-full bg-gray-100'>
            <Search
              setEditTask={setEditTask}
              data={data}
              updateFilter={updateFilter}
              filter={filter}
              lookupsLoading={lookupsLoading}
              lookups={lookups}
            />
            <div className='sm:col-start-2 sm:col-end-13 flex flex-col sm:h-full sm:overflow-y-auto'>
              <Message message={error.toString()} />
            </div>
          </div>
        </ResponsiveDrawer>
        {modals}
      </>
    )

  if (!tasksLoading && !error && data?.tasks.length <= 0)
    return (
      <>
        <ResponsiveDrawer>
          <div className='sm:grid sm:grid-cols-3 md:grid-cols-4 h-screen w-full sm:overflow-hidden bg-gray-100'>
            <Search
              setEditTask={setEditTask}
              data={data}
              updateFilter={updateFilter}
              filter={filter}
              lookupsLoading={lookupsLoading}
              lookups={lookups}
            />
            <div className='sm:col-start-2 sm:col-end-13 flex flex-col sm:h-full sm:overflow-y-auto'>
              <Message message='No Tasks Found' />
            </div>
          </div>
        </ResponsiveDrawer>
        {modals}
      </>
    )

  return (
    <>
      <ResponsiveDrawer>
        <div className='sm:grid sm:grid-cols-3 md:grid-cols-4 h-screen w-full sm:overflow-hidden bg-gray-100'>
          <Search
            setEditTask={setEditTask}
            data={data}
            updateFilter={updateFilter}
            filter={filter}
            lookupsLoading={lookupsLoading}
            lookups={lookups}
          />

          <div className='sm:col-start-2 sm:col-end-13 flex flex-col sm:h-full sm:overflow-y-auto'>
            <InfiniteScroll
              loader={
                <div className='loader' key={0}>
                  Loading ...
                </div>
              }
              hasMore={!!data.metadata.after}
              useWindow={false}
              loadMore={() => {
                fetchMore({
                  variables: {
                    pagination: { after: data.metadata.after },
                  },
                })
              }}
            >
              {Object.keys(taskGroup).map((key) => (
                <div key={key}>
                  <div className='font-bold p-2 text-4xl'>{key}</div>
                  <div className='w-full md:flex md:flex-row flex-wrap'>
                    {taskGroup[key].map((task) => {
                      return (
                        <div className='sm:w-80 p-1' key={task.id}>
                          <TaskTile
                            task={task}
                            drivers={lookups?.drivers}
                            open={() => showTask(task.id)}
                            audit={() => showAudit(task.id)}
                            edit={() => setEditTask(task)}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </ResponsiveDrawer>
      {modals}
    </>
  )
}

const Search = ({
  data,
  updateFilter,
  filter,
  lookupsLoading,
  lookups,
  setEditTask,
}) => (
  <div className='sm:h-full sm:overflow-y-auto'>
    <div className='flex flex-col p-2'>
      <div className='font-medium'>Tasks ({data.metadata.totalCount})</div>

      <Button
        //component={Link}
        //to={`/dashboard/tasks/new`}
        onClick={() => setEditTask({})}
        variant='outlined'
        style={{
          backgroundColor: 'green',
          color: 'white',
          width: '100%',
        }}
      >
        Create Task
      </Button>

      <FormFieldBuilder
        field={{
          md: 12,
          name: 'title',
          label: 'Search Title/Passenger',
        }}
        onChange={updateFilter}
        value={filter.title}
      />

      <FormFieldBuilder
        field={{
          md: 12,
          name: 'driverId',
          label: 'Assigned to',
          type: 'dropdown',
          options:
            !lookups || !lookups.drivers
              ? [{ value: null, name: 'None' }]
              : (lookups.drivers || [])
                  .filter((user) => user.active)
                  .map((driver) => {
                    return {
                      value: driver?.id,
                      name: driver?.name,
                    }
                  })
                  .concat([{ value: null, name: 'None' }]),
        }}
        onChange={updateFilter}
        value={filter.driverId}
      />

      <FormFieldBuilder
        field={{
          md: 12,
          name: 'dispatcherId',
          label: 'Dispatcher',
          type: 'dropdown',
          options:
            !lookups || !lookups.dispatchers
              ? [{ value: null, name: 'None' }]
              : (lookups.dispatchers || [])
                  .filter((user) => user.active)
                  .map((dispatcher) => {
                    return {
                      value: dispatcher?.id,
                      name: dispatcher?.name,
                    }
                  })
                  .concat([{ value: null, name: 'None' }]),
        }}
        onChange={updateFilter}
        value={filter.dispatcherId}
      />

      <FormFieldBuilder
        field={{
          md: 12,
          name: 'type',
          label: 'Type',
          type: 'dropdown',
          options:
            !lookupsLoading &&
            !!lookups &&
            (lookups.taskTypes?.values || [])
              .map((type) => ({
                value: type.name,
                name: type.name,
              }))
              .concat([{ value: undefined, name: 'All' }]),
        }}
        onChange={updateFilter}
        value={filter.type}
      />

      <FormFieldBuilder
        field={{
          md: 12,
          name: 'feature',
          label: 'Feature',
          type: 'dropdown',
          options:
            !lookupsLoading &&
            !!lookups &&
            (lookups.features?.values || [])
              .map((type) => ({
                value: type.name,
                display: (
                  <div key={type.name}>
                    {icon(type.name)} {type.name}
                  </div>
                ),
              }))
              .concat([{ value: undefined, name: 'None' }]),
        }}
        onChange={updateFilter}
        value={filter.feature}
      />

      <FormFieldBuilder
        field={{
          md: 12,
          name: 'date',
          label: 'Date',
          type: 'date',
        }}
        onChange={updateFilter}
        value={filter.date}
      />

      <FormFieldBuilder
        field={{
          md: 12,
          name: 'dateRange',
          label: 'Date Range',
          type: 'dropdown',
          options:
            !lookupsLoading &&
            !!lookups &&
            (lookups.dateRange?.values || []).map((type) => ({
              value: type.name,
              name: type.name,
            })),
        }}
        onChange={updateFilter}
        value={filter.dateRange}
      />
      <div className='flex flex-row'>
        <FormFieldBuilder
          field={{
            xs: 6,
            name: 'flagged',
            label: 'Flagged',
            type: 'boolean',
          }}
          onChange={updateFilter}
          value={filter.flagged}
        />
        <FormFieldBuilder
          field={{
            xs: 6,
            name: 'deleted',
            label: 'Deleted',
            type: 'boolean',
          }}
          onChange={updateFilter}
          value={filter.deleted}
        />
      </div>
    </div>
  </div>
)

export default TasksPage
