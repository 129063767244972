import React, { useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
//import Paper from '@material-ui/core/Paper'
//import { makeStyles } from '@material-ui/core/styles'
import ResponsiveDrawer from '../components/drawer'

const INVOICES = gql`
  query {
    invoices {
      id
      total
      status
      checkNumber

      user {
        name
      }
      tasks {
        id
      }
    }
  }
`
export const InvoicesPage = () => {
  // const [filter, setFilter] = useState({ type: '', name: '' })

  let [getInvoices, { loading, error, data, called, refetch }] = useLazyQuery(
    INVOICES,
    {
      fetchPolicy: 'cache-and-network',
    }
  )

  data = data || { invoices: [] }

  useEffect(() => {
    // if (called) {
    //   refetch()
    // } else {
    getInvoices()
    // }
  }, [getInvoices, refetch, called])

  return (
    <ResponsiveDrawer>
      <div className='p-2 bg-gray-100 h-screen'>
        <div className='flex flex-row justify-between items-center'>
          <div className=''>Invoices</div>

          <Button
            component={Link}
            to='/dashboard/invoices/new'
            variant='outlined'
          >
            Create Invoice
          </Button>
        </div>

        <div className=''>
          {loading && !data.invoices.length && (
            <div className='p-4 flex items-center justify-center text-4xl'>
              loading...
            </div>
          )}

          {error && (
            <div className='p-4 flex items-center justify-center text-4xl'>
              {error.toString()}
            </div>
          )}

          {!loading && !data.invoices.length && (
            <div className='p-4 flex items-center justify-center text-4xl'>
              No invoices
            </div>
          )}

          {data.invoices.map((invoice) => (
            <div
              className='flex flex-row px-2 items-center border-b-2'
              button
              key={invoice.id}
            >
              <div className='flex flex-col p-1 w-14'>
                <div className='text-xs text-right'>#{invoice.id}</div>
                <div className='text-xs text-right'> {invoice.status}</div>
                <div className='text-xs text-right'>
                  ${(invoice.total || 0) / 100}
                </div>
              </div>

              <div className='flex flex-row flex-1 p-1 space-x-1'>
                <div>
                  {' '}
                  Invoice for{' '}
                  <span className='font-bold'>{invoice.user.name}</span>
                </div>
                {!!invoice.checkNumber && (
                  <div>
                    with check{' '}
                    <span className='font-bold'>{invoice.checkNumber}</span>
                  </div>
                )}
              </div>

              <Button
                component={Link}
                to={`/dashboard/invoices/${invoice.id}/edit`}
              >
                Edit
              </Button>
            </div>
          ))}
        </div>
      </div>
    </ResponsiveDrawer>
  )
}

export default InvoicesPage
