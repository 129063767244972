import React, { useEffect } from 'react'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import ListItem from '@material-ui/core/ListItem'
import { ListItemText, AppBar, Toolbar, IconButton } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory, Link } from 'react-router-dom'
import { useQuery, gql, useSubscription } from '@apollo/client'
import session from '../data/session'
import { LoginPage } from '../pages/login'
import { DateTime } from 'luxon'

const drawerWidth = 230

const ME = gql`
  query Me {
    me {
      id
      type
      username
      name
    }
  }
`

const USERS = gql`
  query USERS {
    users {
      id
      type
      name
      active
      tasks {
        id
      }
    }
  }
`

const TASKS_DRAWER = gql`
  query TASKS_DRAWER {
    all: taskCount {
      total
    }
    today: taskCount(filter: { dateRange: DAY }) {
      total
    }
    week: taskCount(filter: { dateRange: WEEK }) {
      total
    }
    month: taskCount(filter: { dateRange: MONTH }) {
      total
    }
    paid: taskCount(filter: { status: PAID }) {
      total
    }
    pending: taskCount(filter: { status: PENDING }) {
      total
    }
    unassigned: taskCount(filter: { status: UNASSIGNED }) {
      total
    }
    completed: taskCount(filter: { status: COMPLETED }) {
      total
    }
    bee: taskCount(filter: { feature: BEE }) {
      total
    }
  }
`

const TASKS_SUB = gql`
  subscription TasksChanged {
    tasksChanged {
      id
      driver {
        id
      }
    }
  }
`

let today = DateTime.now().toISO({ zone: 'America/Barbados' }).toString()

function ResponsiveDrawer(props) {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const { data } = useQuery(ME)
  const { data: userData, refetch: usersRefetch } = useQuery(USERS)
  const { data: tasksData, refetch: tasksRefetch } = useQuery(TASKS_DRAWER)
  const { data: taskData } = useSubscription(TASKS_SUB)

  useEffect(() => {
    usersRefetch && usersRefetch()
    tasksRefetch && tasksRefetch()
  }, [taskData, usersRefetch, tasksRefetch])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  if (!data) return <div />
  if (!session.isAuthenticated()) return <LoginPage />

  const drawer = (
    <div className='flex flex-col h-screen'>
      <div className='w-full overflow-y-auto flex-auto'>
        <ListItem component={Link} to='/dashboard/tasks?dateRange=ALL' button>
          <ListItemText primary={`All Tasks (${tasksData?.all.total || 0})`} />
        </ListItem>
        <Divider />
        <ListItem
          component={Link}
          to={`/dashboard/tasks?dateRange=DAY&date=${today}`}
          button
        >
          <ListItemText
            primary={`Today Tasks (${tasksData?.today.total || 0})`}
          />
        </ListItem>

        <ListItem
          component={Link}
          to={`/dashboard/tasks?dateRange=WEEK&date=${today}`}
          button
        >
          <ListItemText
            primary={`Week Tasks (${tasksData?.week.total || 0})`}
          />
        </ListItem>

        <ListItem
          component={Link}
          to={`/dashboard/tasks?dateRange=MONTH&date=${today}`}
          button
        >
          <ListItemText
            primary={`Month Tasks (${tasksData?.month.total || 0})`}
          />
        </ListItem>
        <Divider />
        <ListItem
          component={Link}
          to='/dashboard/tasks?status=UNASSIGNED'
          button
        >
          <ListItemText
            primary={`Unassigned Tasks (${tasksData?.unassigned.total || 0})`}
          />
        </ListItem>

        <ListItem component={Link} to='/dashboard/tasks?status=PENDING' button>
          <ListItemText
            primary={`Pending Tasks (${tasksData?.pending.total || 0})`}
          />
        </ListItem>

        <ListItem
          component={Link}
          to={`/dashboard/tasks?status=COMPLETED`}
          button
        >
          <ListItemText
            primary={`Completed Tasks (${tasksData?.completed.total || 0})`}
          />
        </ListItem>

        {/*<ListItem
          component={Link}
          to={`/dashboard/tasks?status=COMPLETED&dateRange=MONTH&date=${today}`}
          button
        >
          <ListItemText
            primary={`Completed Tasks (${tasksData?.completed.total || 0})`}
          />
        </ListItem>*/}

        {/* <ListItem component={Link} to={`/dashboard/tasks?status=PAID`} button>
          <ListItemText
            primary={`Paid Tasks (${tasksData?.paid.total || 0})`}
          />
        </ListItem> */}

        <ListItem component={Link} to='/dashboard/tasks?feature=BEE' button>
          <ListItemText primary={`BEE (${tasksData?.bee.total || 0})`} />
        </ListItem>
        {/* <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto'
          }}
        > */}
        <Divider />
        {userData?.users
          ?.filter((user) => user.active)
          .map((user) => (
            <ListItem
              key={user.id}
              component={Link}
              to={`/dashboard/tasks?driverId=${user.id}`}
              button
            >
              <ListItemText
                primary={`${user.name} (${user.tasks?.length || 0})`}
              />
            </ListItem>
          ))}
        {/* </div> */}
      </div>

      <div>
        <Divider />
        <ListItem style={{ backgroundColor: '#FD7300', color: 'white' }}>
          <ListItemText primary={`${data.me.name}`} />
        </ListItem>
        <Divider />
        <ListItem component={Link} to='/dashboard/forms/transfer' button>
          <ListItemText primary='Transfers' />
        </ListItem>
        <ListItem component={Link} to='/dashboard/forms/attraction' button>
          <ListItemText primary='Attractions' />
        </ListItem>
        <ListItem component={Link} to='/dashboard/forms/fasttrack' button>
          <ListItemText primary='Fast Tracks' />
        </ListItem>
        <ListItem component={Link} to='/dashboard/forms/covidtest' button>
          <ListItemText primary='Covid Tests' />
        </ListItem>
        <ListItem component={Link} to='/dashboard/invoices' button>
          <ListItemText primary='Invoices' />
        </ListItem>
        <ListItem component={Link} to='/dashboard/users' button>
          <ListItemText primary='Manage Users' />
        </ListItem>
        <Divider />
        <ListItem
          button
          onClick={(e) => session.signout(() => history.push('/'))}
        >
          <ListItemText primary='Log out' />
        </ListItem>
        {/* <ListItem>
          <ListItemText primary={data.me.type} />
        </ListItem> */}
        {/* <div className={classes.toolbar} /> */}
      </div>
    </div>
  )

  return (
    <div className='flex h-screen w-screen overflow-y-auto'>
      {/* <CssBaseline /> */}
      <Hidden smUp implementation='css'>
        <AppBar position='fixed' className={classes.appBar}>
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography variant='h6' noWrap>
            Responsive drawer
          </Typography> */}
          </Toolbar>
        </AppBar>
      </Hidden>
      <nav className={classes.drawer} aria-label='mailbox folders'>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation='css'>
          <Drawer
            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant='permanent'
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <div className='flex-auto'>
        {props.children || (
          <div className='flex justify-center items-center h-screen w-full'>
            Welcome
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    overflow: 'hidden',
  },
  content: {
    // padding: theme.spacing(2),

    overflow: 'auto',
  },
}))

export default ResponsiveDrawer
