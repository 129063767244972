import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { format, parseISO } from 'date-fns'

// import GoogleMapReact from 'google-map-react'

const AUDIT = gql`
  query audits($filter: AuditFilter!) {
    audits(filter: $filter) {
      id
      action
      actorId
      actor {
        id
        username
        type
      }
      entityId
      entitySchema
      patch {
        field
        type
        oldValue
        newValue
      }
      recordedAt
      rollback
    }
  }
`

export default function AuditPage({ taskId }) {
  const { id: paramId } = useParams()
  let id = taskId || paramId

  let {
    loading: auditLoading,
    error: auditError,
    data: auditData,
  } = useQuery(AUDIT, {
    variables: { filter: { entity_schema: 'TASK', entity_id: +id } },
    fetchPolicy: 'cache-and-network',
  })

  if (auditLoading)
    return (
      <div className='h-32 w-96 flex items-center justify-center text-xl font-bold'>
        Loading...
      </div>
    )

  if (auditError)
    return (
      <div className='h-32 w-96 flex items-center justify-center'>
        {auditError.toString()}
      </div>
    )

  return (
    <Grid item alignItems='stretch'>
      <Grid item container>
        <Grid
          component='h4'
          item
          xs={12}
          className='bg-orange-100 flex justify-center items-center h-9 font-bold'
        >
          Audit Log
        </Grid>
        <div className='overflow-auto max-h-96 w-full'>
          {!auditData?.audits?.length && 'no audit'}

          {auditData?.audits?.length && (
            <div>
              {auditData.audits.map((version) => (
                <div key={version.id} className='p-2 border-b-2 '>
                  <p className='font-semibold'>
                    {version.action} by {version.actor?.username} (
                    {version.actor?.type})
                  </p>
                  <p className='mb-3'>
                    {format(
                      parseISO(version.recordedAt),
                      'h:mm a, dd EEEE, MMMM yyy'
                    )}
                  </p>
                  {version.patch.map((patch) => (
                    <div key={patch.field}>
                      {patch.type === 'added' && (
                        <p>
                          {patch.field}: {patch.newValue}
                        </p>
                      )}

                      {patch.type === 'changed' && (
                        <p>
                          <span>
                            {patch.type} {patch.field} from{' '}
                          </span>
                          {patch.oldValue || '[BLANK]'}{' '}
                          <span className='font-bold'>to </span>
                          {patch.newValue || '[BLANK]'}
                        </p>
                      )}

                      {patch.type === 'added_to_list' && (
                        <p>
                          added {patch.newValue}{' '}
                          <span className='font-bold'>to </span> {patch.field}
                        </p>
                      )}

                      {patch.type === 'removed_from_list' && (
                        <p>
                          removed {patch.newValue}{' '}
                          <span className='font-bold'>from </span> {patch.field}
                        </p>
                      )}

                      {patch.type === 'changed_in_list' && (
                        <>
                          <p>
                            removed {patch.oldValue}{' '}
                            <span className='font-bold'>from </span>{' '}
                            {patch.field}
                          </p>
                          <p>
                            added {patch.newValue}{' '}
                            <span className='font-bold'>to </span> {patch.field}
                          </p>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  )
}
