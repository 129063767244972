import React from 'react'
import logo from '../images/logo.png'

export const FastTrackCompletePage = () => {
  return (
    <div className='flex h-screen w-screen flex-col items-center justify-center  bg-gray-100'>
      <div className='p-4'>
        <img src={logo} alt='logo' />
      </div>

      <h1 className='text-2xl mb-2 text-gray-800'>Thank you. Form Submitted</h1>

      <a
        href={`/invoice/fasttrackform`}
        className='mt-4 bg-transparent border border-black rounded-lg p-2'
      >
        Create New Fast Track
      </a>
    </div>
  )
}

export default FastTrackCompletePage
