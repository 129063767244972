import React, { useState, useEffect } from 'react'
import { useQuery, gql, useMutation } from '@apollo/client'
//import { Link } from 'react-router-dom'
import ResponsiveDrawer from '../components/drawer'
import { List, ListItem, ListItemText, Dialog, Button } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { pickBy, identity } from 'lodash'
import { DateTime } from 'luxon'
import EditTaskModal from './editTaskModal'
import { dateTimeFromServer } from '../data/helpers'

const ATTRACTIONS_PERSONS = gql`
  query AttractionPersons($filter: AttractionPersonFilter) {
    attractionPersons(filter: $filter) {
      id
      firstName
      lastName

      attraction {
        id
        date
        personCount
        request
        email
        telephone
        localAddress
        completed
        deleted
        insertedAt

        event {
          name
          url
          active
        }
      }
    }
  }
`

const ATTRACTION_UPDATE = gql`
  mutation updateAttraction($input: UpdateAttractionInput!, $id: ID!) {
    updateAttraction(input: $input, id: $id) {
      id
      completed
      deleted
    }
  }
`

let dateFromServer = (date) =>
  date &&
  DateTime.fromISO(date, {
    zone: 'America/Barbados',
  })
    .toFormat('DD')
    .toString()

export const AttractionsPage = () => {
  const [filter, setFilter] = useState({})
  const [editTask, setEditTask] = useState(null)

  let { loading, error, data, refetch } = useQuery(ATTRACTIONS_PERSONS, {
    variables: { filter: pickBy(filter, identity) },
  })

  data = data || { attractionPersons: [] }

  const [update] = useMutation(ATTRACTION_UPDATE)

  useEffect(() => {
    refetch({ filter: pickBy(filter, identity) })
  }, [filter, refetch])

  return (
    <ResponsiveDrawer>
      <div className='p-2 bg-gray-100 h-screen'>
        <Paper>
          <List component='nav' aria-label='main users'>
            {loading && !data.attractionPersons.length && (
              <ListItem>
                <ListItemText primary={<p>loading...</p>} />
              </ListItem>
            )}

            {error && (
              <ListItem>
                <ListItemText primary={<p>{error.toString()}</p>} />
              </ListItem>
            )}

            <div className='flex flex-row  m-2 p-2 space-x-5'>
              <span>Filters:</span>
              <div className='space-x-1 flex items-center'>
                <input
                  type='checkbox'
                  id='completed'
                  name='completed'
                  onChange={(e) => {
                    setFilter({ ...filter, completed: !filter.completed })
                  }}
                  value={filter.completed}
                />

                <label for='completed'>Completed</label>
              </div>
              <div className='space-x-1 flex items-center'>
                <input
                  type='checkbox'
                  id='deleted'
                  name='deleted'
                  onChange={(e) => {
                    setFilter({ ...filter, deleted: !filter.deleted })
                  }}
                  value={filter.deleted}
                />

                <label for='deleted'>Deleted</label>
              </div>
            </div>

            <table className='w-full'>
              <tr className='border'>
                <th className='border transform rotate-45 w-2'></th>
                <th className='border transform rotate-45 w-2'>Completed</th>
                <th className='border transform rotate-45 w-2'>Deleted</th>

                <th className='border'>Date Created</th>
                <th className='border'>Order #</th>
                <th className='border'>Date</th>
                <th className='border'># Persons</th>

                <th className='border'>Attraction</th>
                <th className='border'>First Name</th>
                <th className='border'>Last Name</th>
                <th className='border'>Address in Barbados</th>
                <th className='border'>Email</th>
                <th className='border'>Telephone #</th>
                <th className='border'>Special Request</th>
              </tr>
              <tbody>
                {data.attractionPersons.map((attract) => (
                  <tr>
                    <td className='border text-center'>
                      <Button
                        onClick={() => {
                          setEditTask({
                            type: 'ACTIVITY',
                            pickupLocationType: 'ATTRACTION',
                            passengerCount: attract.attraction.personCount,
                            date: attract.attraction.date,
                            flightNumber: attract.attraction.event.name,
                            title: `${attract.firstName} ${attract.lastName}`,
                            pickupLocation: attract.attraction.localAddress,
                            passengerContact: attract.attraction.telephone,
                            passengerName: data.attractionPersons
                              .filter(
                                (t) => t.attraction.id === attract.attraction.id
                              )
                              .map((t) => `${t.firstName} ${t.lastName}`)
                              .join(' & '),
                            note:
                              `#${attract.attraction.id}\n` +
                              `${dateFromServer(attract.attraction.date)}\n\n` +
                              `Attraction / Experience: ${attract.attraction.event.name}\n` +
                              `Telephone: ${attract.attraction.telephone}\n` +
                              `Email: ${attract.attraction.email}\n\n` +
                              `${data.attractionPersons
                                .filter(
                                  (t) =>
                                    t.attraction.id === attract.attraction.id
                                )
                                .map(
                                  (t) => `Guest: ${t.firstName} ${t.lastName}\n`
                                )
                                .join('\n')}` +
                              `\n${attract.attraction.request}`,
                          })
                        }}
                        variant='outlined'
                        style={{
                          backgroundColor: 'green',
                          color: 'white',
                          width: '100%',
                        }}
                      >
                        Create Task
                      </Button>
                    </td>
                    <td className='border text-center'>
                      <input
                        type='checkbox'
                        name='completed'
                        onChange={async (e) => {
                          await update({
                            variables: {
                              id: attract.attraction.id,
                              input: {
                                completed: e.target.checked,
                              },
                            },
                          })
                        }}
                        checked={attract.attraction.completed}
                        value={attract.attraction.completed}
                      />
                    </td>
                    <td className='border text-center'>
                      <input
                        type='checkbox'
                        name='deleted'
                        onChange={async (e) => {
                          await update({
                            variables: {
                              id: attract.attraction.id,
                              input: {
                                deleted: e.target.checked,
                              },
                            },
                          })
                        }}
                        checked={attract.attraction.deleted}
                        value={attract.attraction.deleted}
                      />
                    </td>
                    <td className='border text-center'>
                      {dateTimeFromServer(attract.attraction.insertedAt)}
                    </td>
                    <td className='border text-center'>
                      {attract.attraction.id}
                    </td>
                    <td className='border text-center'>
                      {dateFromServer(attract.attraction.date)}
                    </td>
                    <td className='border text-center'>
                      {attract.attraction.personCount}
                    </td>
                    <td className='border text-center'>
                      {attract.attraction.event.name}
                    </td>
                    <td className='border text-center'>{attract.firstName}</td>
                    <td className='border text-center'>{attract.lastName}</td>
                    <td className='border text-center'>
                      {attract.attraction.localAddress}
                    </td>
                    <td className='border text-center'>
                      {attract.attraction.email}
                    </td>
                    <td className='border text-center'>
                      {attract.attraction.telephone}
                    </td>
                    <td className='border text-center'>
                      {attract.attraction.request}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </List>
        </Paper>
      </div>

      {!!editTask && (
        <Dialog
          maxWidth='md'
          //onBackdropClick={() => setEditTask(null)}
          onClose={(_event, reason) => {
            if (reason !== 'backdropClick') {
              setEditTask(null)
            }
          }}
          open={!!editTask}
        >
          {editTask && (
            <EditTaskModal task={editTask} close={() => setEditTask(null)} />
          )}
        </Dialog>
      )}
    </ResponsiveDrawer>
  )
}

export default AttractionsPage
