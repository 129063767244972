import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQuery, gql } from '@apollo/client'
import session from '../data/session'
import GA from 'react-ga'
import logo from '../images/logo.png'
import android from '../images/android.png'
import ios from '../images/ios.png'

const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      token
    }
  }
`

const COMPANIES = gql`
  query CompaniesData {
    companies {
      id
      name
      active
      visible
    }
  }
`

export const LoginPage = () => {
  let history = useHistory()
  let location = useLocation()
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()
  const [company, setCompany] = useState(1)
  let { data: companies } = useQuery(COMPANIES)

  const [doLogin, { loading }] = useMutation(LOGIN, {
    fetchPolicy: 'no-cache',
    update: (cache, response) => {
      // console.log(response.data.login.token)
    },
  })

  let { from } = location.state || { from: { pathname: '/dashboard/tasks' } }

  let login = async (e) => {
    e.preventDefault()
    try {
      let result = await doLogin({
        variables: { input: { username, password, company } },
      })

      session.authenticate(result.data.login.token)
      GA.send('login', { method: 'username' })
      history.replace(from)
    } catch (error) {
      console.log(error.message)
    }
  }

  return (
    <div className='flex flex-col h-screen w-screen'>
      <div className='flex flex-auto flex-col justify-center items-center sm:self-center'>
        <form
          onSubmit={login}
          className='container flex flex-col justify-center items-center space-y-3 p-4 sm:w-96 h-auto border-solid sm:border rounded-md sm:shadow bg-white'
        >
          <div style={{ paddingBottom: 20 }}>
            <img src={logo} alt='logo' />
          </div>

          <div className='relative w-full'>
            <select
              className='w-full border-b appearance-none border-gray-500 py-2 focus:outline-none focus:ring-2  text-base pl-2  pr-10'
              onChange={(e) => setCompany(e.target.value)}
              value={company}
            >
              {companies &&
                companies.companies.length &&
                companies.companies.map((option) => (
                  <option value={option.id} key={option.id}>
                    {option.name}
                  </option>
                ))}
            </select>
            <span className='absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center'>
              <svg
                fill='none'
                stroke='currentColor'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                className='w-4 h-4'
                viewBox='0 0 24 24'
              >
                <path d='M6 9l6 6 6-6'></path>
              </svg>
            </span>
          </div>

          <div className='w-full'>
            <label
              htmlFor='username'
              className='leading-7 text-sm text-gray-600'
            >
              Username
            </label>
            <input
              type='text'
              id='username'
              name='username'
              onChange={(event) => setUsername(event.target.value)}
              className='w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out hover:border-gray-600'
            />
          </div>

          <div className='w-full relative pb-1'>
            <label
              htmlFor='password'
              className='leading-7 text-sm text-gray-600'
            >
              Password*
            </label>
            <input
              type='password'
              id='password'
              name='password'
              autoComplete='current-password'
              required
              onChange={(event) => setPassword(event.target.value)}
              className='w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out hover:border-gray-600'
            />
          </div>

          <button
            type='submit'
            disabled={loading}
            className='w-full px-3 py-3 text-white bg-green-600 focus:bg-green-700 rounded-md focus:outline-none'
          >
            Log in
          </button>
        </form>
      </div>

      <div className='flex flex-col content-center justify-center'>
        <div className='text-center'>Download App</div>
        <div className='flex flex-row justify-center space-x-4 py-4'>
          <a href='https://play.google.com/store/apps/details?id=com.bluisles.islandescapes'>
            <img className='h-20' src={android} alt='android' />
          </a>
          <a href='itms-services://?action=download-manifest&url=https://islandescapes.bluisles.com/manifest.plist'>
            <img className='h-20' src={ios} alt='ios' />
          </a>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
