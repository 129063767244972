import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { client } from './data/client'

import GA from 'react-ga'
import Loading from './components/loading'

GA.initialize('G-Q0Q98CEW94', { siteSpeedSampleRate: 100, debug: false })

let Application = () => (
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <App className='sm:bg-gray-100' />
      </Suspense>
    </BrowserRouter>
  </ApolloProvider>
)

ReactDOM.render(<Application />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
