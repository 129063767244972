import React, { useState, useEffect } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { Link } from 'react-router-dom'
import ResponsiveDrawer from '../components/drawer'
import {
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Grid,
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { pickBy, identity } from 'lodash'

const USERS = gql`
  query users($filter: UsersFilter) {
    users(filter: $filter) {
      id
      name
      username
      type
    }

    types: __type(name: "UserType") {
      name
      values: enumValues {
        name
      }
    }
  }
`
export const UsersPage = () => {
  const classes = useStyles()
  const [filter, setFilter] = useState({ type: '', name: '' })

  let [getUsers, { called, loading, error, data, refetch }] = useLazyQuery(
    USERS,
    {
      variables: { filter: pickBy(filter, identity) },
    }
  )
  data = data || { users: [], types: { values: [] } }

  useEffect(() => {
    if (called) {
      refetch({ filter: pickBy(filter, identity) })
    } else {
      getUsers()
    }
  }, [filter, called, refetch, getUsers])

  const updateFilter = (e) => {
    const { name, value } = e.target
    setFilter((previousFilter) => {
      return {
        ...previousFilter,
        [name]: value,
      }
    })
  }
  return (
    <ResponsiveDrawer>
      <div className='p-2 bg-gray-100 h-screen' style={{ padding: 8 }}>
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Grid item component='h3'>
            Users
          </Grid>
          <Grid item>
            <Button
              component={Link}
              to={`/dashboard/users/new`}
              variant='outlined'
            >
              Create Account
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item className={classes.formControl} component={FormControl}>
            <TextField
              id='usero-input'
              label='Search users'
              value={filter.name}
              name='name'
              margin='none'
              onChange={updateFilter}
              autoComplete='off'
            />
          </Grid>
          <Grid item>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor='type-select'>Type</InputLabel>
              <Select
                label='Type'
                value={filter.type}
                onChange={updateFilter}
                inputProps={{
                  name: 'type',
                  id: 'type-select',
                }}
              >
                <MenuItem value=''>All</MenuItem>
                {data.types.values.map((type) => (
                  <MenuItem value={type.name} key={type.name}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Paper className={classes.root}>
          <List component='nav' aria-label='main users'>
            {loading && !data.users.length && (
              <ListItem>
                <ListItemText primary={<p>loading...</p>} />
              </ListItem>
            )}

            {error && (
              <ListItem>
                <ListItemText primary={<p>{error.toString()}</p>} />
              </ListItem>
            )}

            {data.users.map((user) => (
              <ListItem button key={user.id}>
                <ListItemText>
                  {user.name} ({user.username}){' '}
                  <Chip size='small' label={user.type} />
                </ListItemText>

                <Button component={Link} to={`/dashboard/users/${user.id}`}>
                  View
                </Button>
                <Button
                  component={Link}
                  to={`/dashboard/users/${user.id}/edit`}
                >
                  Edit
                </Button>
              </ListItem>
            ))}
          </List>
        </Paper>
      </div>
    </ResponsiveDrawer>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    overflowX: 'auto',
  },
  formControl: {
    minWidth: 70,
  },
}))

export default UsersPage
