import React, { useState } from 'react'
import { Button, MenuItem } from '@material-ui/core'
import { useMutation, useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import logo from '../images/logo.png'
import {
  type,
  insertItem,
  removeItems,
  updateObjectInArray,
  TextField,
  DropdownField,
} from '../common'

const LOOKUPS = gql`
  query Lookups {
    transferType: __type(name: "TransferType") {
      name
      values: enumValues {
        name
      }
    }
  }
`

const TRANSFER_LOCATION = gql`
  query Lookups {
    transfers: transferLocations {
      id
      name
      url
    }
  }
`

const CREATE_TRANSFER = gql`
  mutation createTransfer($input: CreateTransferInput!) {
    createTransfer(input: $input) {
      id
    }
  }
`

let fieldClass = 'flex flex-col items-stretch sm:w-1/2 w-full p-2'

let dateFromField = (date) =>
  date &&
  DateTime.fromFormat(date, 'yyyy-MM-dd', {
    zone: 'America/Barbados',
  }).toISO()

export const CreateTransferForm = () => {
  const [info, setInfo] = useState({
    personCount: 1,
    persons: [{ id: 0 }],
  })

  let updateInfo = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value })
  }

  let { data: locations, loading: locationsLoading } =
    useQuery(TRANSFER_LOCATION)

  let { data: lookups, loading: lookupsLoading } = useQuery(LOOKUPS)

  const [create, { loading: createLoading }] = useMutation(CREATE_TRANSFER)

  let save = async (e) => {
    e.preventDefault()

    //if (info.date.includes('-12-25')) {
    //  snackbar.error('No Bookings on the 25th of December')

    //  return
    //}

    try {
      await create({
        variables: {
          input: {
            ...info,
            date: dateFromField(info.date),
            locationDate: dateFromField(info.locationDate),
            persons: info.persons.map((person) => {
              return {
                ...person,
              }
            }),
          },
        },
      })

      let location = locations.transfers.find(
        (d) => +d.id === +info.transfer_location_id
      )

      window.location.href = location.url
    } catch (error) {
      //alert(error.message)
    }
  }

  return (
    <div className='flex h-screen w-screen flex-col items-center bg-gray-100'>
      <div className='p-4'>
        <img src={logo} alt='logo' />
      </div>

      <h1 className='text-2xl mb-2 text-gray-800'>Transfers</h1>

      <div
        className='h-full w\
      -full flex justify-center  overflow-auto'
      >
        <form
          className='h-full sm:w-5/6 md:w-4/6 flex flex-row items-center flex-wrap content-start mb-2'
          onSubmit={save}
        >
          {/*<div className='w-full'> {JSON.stringify(info)} </div>*/}

          <div className='flex flex-row flex-wrap w-full bg-white rounded-lg shadow-lg  pb-4 items-end'>
            <DropdownField
              required
              info={info}
              field='type'
              title='Arrival or Departure'
              classes={fieldClass}
              updateInfo={updateInfo}
              data={
                !lookupsLoading
                  ? lookups.transferType.values.map((lookup) => (
                      <MenuItem value={lookup.name} key={lookup.name}>
                        {type(lookup.name)}
                      </MenuItem>
                    ))
                  : []
              }
            />

            <TextField
              info={info}
              field='date'
              title='Date of Transfer'
              classes={fieldClass}
              updateInfo={updateInfo}
              type='date'
              required
            />

            <DropdownField
              required
              info={info}
              field='transfer_location_id'
              title='Journey'
              classes={fieldClass}
              updateInfo={updateInfo}
              data={
                !locationsLoading
                  ? locations.transfers.map((transfer) => (
                      <MenuItem value={transfer.id} key={transfer.name}>
                        {type(transfer.name)}
                      </MenuItem>
                    ))
                  : []
              }
            />

            <DropdownField
              required
              info={info}
              field='personCount'
              title='Number of Persons'
              classes={fieldClass}
              updateInfo={(e) => {
                //updateInfo(e)

                let listy = removeItems(info.persons, +e.target.value)

                setInfo({
                  ...info,
                  personCount: +e.target.value,
                  persons: listy,
                })
              }}
              num={true}
              data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((lookup) => (
                <MenuItem value={lookup} key={lookup}>
                  {lookup}
                </MenuItem>
              ))}
            />

            <TextField
              info={info}
              field='flightNumber'
              title='Airline and Flight Number'
              classes={fieldClass}
              updateInfo={updateInfo}
              required
            />

            <TextField
              info={info}
              field='email'
              title='Email'
              classes={fieldClass}
              updateInfo={updateInfo}
              required
            />

            <TextField
              info={info}
              field='telephone'
              title='Telephone'
              classes={fieldClass}
              updateInfo={updateInfo}
              required
            />
            <TextField
              info={info}
              field='localAddress'
              title='Hotel / Accommodation'
              classes={fieldClass}
              updateInfo={updateInfo}
              required
            />

            <TextField
              info={info}
              field='request'
              title='Special Request'
              classes={fieldClass}
              updateInfo={updateInfo}
            />
          </div>

          {Array.from(Array(info.personCount).keys()).map((num) => {
            return (
              <CreatePerson
                key={num}
                index={num}
                setData={setInfo}
                data={info}
              />
            )
          })}
          <div className='flex flex-col  w-full mt-4 mb-4'>
            <Button
              type='submit'
              variant='contained'
              disabled={createLoading}
              fullWidth
              //onSubmit={save}
              style={{
                backgroundColor: 'green',
                color: 'white',
              }}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export const CreatePerson = ({ index, update, data, setData }) => {
  const [info] = useState({ id: index })

  let updateInfo = (e) => {
    let item = data.persons.find((d) => +d.id === +index)
    if (item)
      setData({
        ...data,
        persons: updateObjectInArray(data.persons, {
          ...item,
          [e.target.name]: e.target.value,
        }),
      })
    else
      setData({
        ...data,
        persons: insertItem(data.persons, {
          id: index,
          [e.target.name]: e.target.value,
        }),
      })
  }

  return (
    <div className='w-full flex flex-col mt-4 bg-white rounded-lg overflow-hidden shadow-lg  pb-4'>
      {/*<div className='w-full border-black border-t border-opacity-20'></div>*/}
      <div
        className='w-full text-center text-lg text-white font-medium p-1'
        style={{ backgroundColor: '#00B0CA' }}
      >
        Person #{index + 1}
      </div>
      <div className='w-full flex flex-row items-end flex-wrap'>
        <TextField
          info={info}
          field='firstName'
          title='First Name'
          classes={fieldClass}
          updateInfo={updateInfo}
          required
        />

        <TextField
          info={info}
          field='lastName'
          title='Last Name'
          classes={fieldClass}
          updateInfo={updateInfo}
          required
        />
      </div>
    </div>
  )
}

export default CreateTransferForm
