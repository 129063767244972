import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import ResponsiveDrawer from '../components/drawer'
import { Button, Typography, Grid } from '@material-ui/core'

const USER = gql`
  query user($id: Int!) {
    user(id: $id) {
      id
      username
      name
      type
    }
  }
`

export const UserPage = () => {
  const { id } = useParams()
  let { loading, error, data } = useQuery(USER, { variables: { id: +id } })
  data = data || { user: {} }

  return (
    <ResponsiveDrawer>
      <Grid style={{ padding: 8 }}>
        {loading && <p>loading...</p>}
        {error && <p>{error.toString()}</p>}

        <Grid container direction='column'>
          <Grid item>
            <Typography>
              {data.user.name} ({data.user.username})
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{data.user.type}</Typography>
          </Grid>
          <Button component={Link} to={`/dashboard/users/${data.user.id}/edit`}>
            Edit
          </Button>
        </Grid>
      </Grid>
    </ResponsiveDrawer>
  )
}

export default UserPage
