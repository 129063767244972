import React, { useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import { useMutation, useLazyQuery, useQuery, gql } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import { pick } from 'lodash'
import { FormFieldBuilder } from '../components/formFieldBuilder'
import { icon, locationColor } from '../components/icons'
import { dateTimeFromServer } from '../data/helpers'

const formFields = [
  { name: 'title', required: true, md: 12 },
  { name: 'type', required: true, type: 'dropdown' },
  { name: 'date', type: 'date', required: true },
  {
    name: 'date',
    label: 'Time',
    type: 'time',
    required: true,
  },
  { name: 'flightNumber', label: 'Flight Number / Vessel' },
  { name: 'passengerName', label: 'Passenger Name(s)' },
  { name: 'passengerCount', type: 'number', label: 'Passenger Count' },
  { name: 'passengerContact', label: 'Passenger Contact' },
  {
    name: 'pickupLocationType',
    label: 'Location Type',
    type: 'dropdown',
  },
  { name: 'pickupLocation', label: 'Pickup Location' },
  { name: 'dropoffLocation', label: 'Dropoff Location' },
  { name: 'price', type: 'number' },
  {
    name: 'driver_id',
    label: 'Assigned to',
    type: 'dropdown',
  },
  {
    name: 'dispatcher_id',
    label: 'Dispatcher',
    type: 'dropdown',
  },
  { name: 'features', type: 'multiselect', md: 12 },
  { name: 'accepted', type: 'boolean', xs: 6 },
  { name: 'private', type: 'boolean', xs: 6 },
  { name: 'flagged', type: 'boolean', xs: 6 },
  { name: 'deleted', type: 'boolean', xs: 6 },
  { name: 'note', md: 12, type: 'textarea' },
  {
    name: 'internalNote',
    md: 12,
    label: 'Internal Note',
    type: 'textarea',
  },
]

const fieldsToRetrieve = formFields.reduce((p, x) => {
  if (x.name === 'driver_id') return `${p} driver {id}`
  if (x.name === 'dispatcher_id') return `${p} dispatcher {id}`
  return `${p} ${x.name}`
}, 'id')

const TASK = gql`
  query Task($id: Int!) {
    task(id: $id) {
      ${fieldsToRetrieve}
      insertedAt

      creator {
        name
        id
      }

      subTasks {
        id
        title
        completed
      }
    }
  }
`

const LOOKUPS = gql`
  query Lookups {
    drivers: users {
      id
      name
      active
      type
    }

    taskTypes: __type(name: "TaskType") {
      name
      values: enumValues {
        name
      }
    }

    features: __type(name: "Features") {
      name
      values: enumValues {
        name
      }
    }

    pickupLocationType: __type(name: "PickupLocationType") {
      name
      values: enumValues {
        name
      }
    }
  }
`

const CREATE_TASK = gql`
  mutation CreateTask($input: CreateTaskInput!) {
    task: createTask(input: $input) {
      ${fieldsToRetrieve}
    }
  }
`

const UPDATE_TASK = gql`
  mutation UpdateTask($id: Int!, $input: UpdateTaskInput!) {
    task: updateTask(id: $id, input: $input) {
      ${fieldsToRetrieve}
    }
  }
`

// const UPDATE_SUB_TASK = gql`
//     mutation UpdateSubTask($id: Int!, $input: UpdateSubTaskInput!) {
//         updateSubTask(id: $id, input: $input) {
//             id
//             title
//             completed
//         }
//     }
// `

export let locationNameNormalized = (name) => {
  switch (name) {
    case 'COVID_TEST':
      return 'COVID Test'
    case 'BEE':
      return 'BEE/BEEx/BEExL'
    default:
      return name
  }
}

const defaultValues = formFields.reduce((p, x) => {
  if (p[x.name]) return p

  let value = null
  if (x.type === 'date' || x.type === 'time') value = new Date().toISOString()
  if (x.type === 'boolean') value = false
  if (x.type === 'number') value = 0
  if (x.type === 'multiselect') value = []
  p[x.name] = value
  return p
}, {})

export const EditTaskModal = ({ task, close, copy }) => {
  const classes = useStyles()
  const [saving, setSaving] = useState(false)

  const id = task.id

  const creating = !task.id

  const [fields, setFields] = useState({ ...defaultValues, ...task })

  const [getTask, { data: taskData }] = useLazyQuery(TASK, {
    variables: { id: +id },
  })

  const { data: lookups, loading: lookupsLoading } = useQuery(LOOKUPS)

  const [doEdit, { loading: editLoading }] = useMutation(
    creating ? CREATE_TASK : UPDATE_TASK
  )
  // const [doUpdateSubTask] = useMutation(UPDATE_SUB_TASK)

  React.useEffect(() => {
    if (!!id) {
      getTask()
    }
  }, [id, getTask])

  React.useEffect(() => {
    if (taskData) {
      let data = taskData.task
      if (taskData.task.driver) {
        data = { ...data, driver_id: taskData.task.driver.id }
      }
      if (taskData.task.dispatcher) {
        data = { ...data, dispatcher_id: taskData.task.dispatcher.id }
      }

      setFields({ ...data, price: data.price / 100 })
    }
  }, [taskData])

  const driverField = formFields.find((field) => field.name === 'driver_id')
  const dispatcherField = formFields.find(
    (field) => field.name === 'dispatcher_id'
  )

  if (driverField) {
    driverField.options = !lookupsLoading
      ? lookups.drivers
          .filter((user) => user.active)
          .map((driver) => ({
            value: driver.id,
            name: driver.name,
          }))
      : []
    driverField.options.push({ value: null, name: 'None' })
  }

  if (dispatcherField) {
    dispatcherField.options = !lookupsLoading
      ? lookups.drivers
          .filter((user) => user.active)
          .map((dispatcher) => ({
            value: dispatcher.id,
            name: dispatcher.name,
          }))
      : []
    dispatcherField.options.push({ value: null, name: 'None' })
  }

  const typeField = formFields.find((field) => field.name === 'type')
  if (typeField) {
    typeField.options =
      !lookupsLoading &&
      lookups.taskTypes.values.map((type) => ({
        value: type.name,
        name: type.name,
      }))
  }

  const featuresField = formFields.find((field) => field.name === 'features')
  if (featuresField) {
    featuresField.options =
      !lookupsLoading &&
      (lookups.features?.values || []).map((type) => ({
        value: type.name,
        name: type.name,
        display: (
          <div>
            {icon(type.name)} {type.name}
          </div>
        ),
      }))

    featuresField.renderValue = (selected) => (
      <div>
        {selected.map((value) => (
          <div
            key={value}
            label={value}
            style={{ display: 'inline-flex', padding: '0px 4px' }}
          >
            {icon(value)}
          </div>
        ))}
      </div>
    )
    // !lookupsLoading &&
    // lookups.features.values.map(type => ({
    //   value: type.name,
    //   name: type.name
    // }))
  }

  const pickupLocationTypeField = formFields.find(
    (field) => field.name === 'pickupLocationType'
  )
  if (pickupLocationTypeField) {
    pickupLocationTypeField.options =
      !lookupsLoading &&
      lookups.pickupLocationType.values
        .filter((type) => !['OTHER', 'NONE'].includes(type.name))
        .map((type) => ({
          value: type.name,
          name: locationNameNormalized(type.name),
        }))
  }

  const updateFields = ({ name, value }) => {
    // const { name, value } = e.target
    setFields((previousFields) => ({
      ...previousFields,
      [name]: value,
    }))
  }

  const duplicate = async (e) => {
    e.preventDefault()

    if (!task?.id) {
      setSaving(true)

      try {
        const inputData = pick(
          fields,
          formFields.map((x) => x.name)
        )

        await doEdit({
          variables: {
            id: +id,
            input: { ...inputData, price: inputData.price * 100 },
          },
        })

        setSaving(false)
      } catch (error) {
        alert(error.message)
      }
    } else {
      copy()
    }
  }

  const save = async (e) => {
    e.preventDefault()
    setSaving(true)
    try {
      const inputData = pick(
        fields,
        formFields.map((x) => x.name)
      )

      await doEdit({
        variables: {
          id: +id,
          input: { ...inputData, price: inputData.price * 100 },
        },
      })

      close()
    } catch (error) {
      setSaving(false)
      alert(error.message)
    }
  }

  if (saving) {
    return (
      <div className='h-32 w-96 flex items-center justify-center text-xl font-bold'>
        Saving...
      </div>
    )
  }

  return (
    <Grid>
      <Grid
        item
        style={{
          backgroundColor: locationColor(fields.pickupLocationType),
          color: 'white',
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // fontSize: 20,
          height: 40,
        }}
      >
        {creating ? 'New' : 'Edit'} {fields.type} Task
      </Grid>

      <Grid container>
        <Grid
          xs={12}
          style={{ padding: '8px' }}
          container
          item
          component='form'
          onSubmit={save}
          justifyContent='space-around'
          alignContent='center'
          spacing={2}
          className={classes.form}
          noValidate={false}
          autoComplete='off'
        >
          <div className='flex flex-1 flex-col items-center text-lg font-medium'>
            {taskData?.task?.creator && (
              <div>created by {taskData?.task?.creator?.name}</div>
            )}
            {taskData?.task?.creator && (
              <div>{dateTimeFromServer(taskData?.task?.insertedAt)}</div>
            )}
          </div>

          {formFields.map((field) => (
            <FormFieldBuilder
              field={field}
              onChange={updateFields}
              value={fields[field.name]}
              key={field.name + field.type}
            />
          ))}
          {/* {taskData?.task?.subTasks &&
              taskData.task.subTasks.map(action => (
                <Grid
                  key={action.id}
                  item
                  xs={12}
                  justifyContent='space-between'
                  alignItems='center'
                  container
                >
                  <Grid item>
                    <Checkbox
                      size='small'
                      checked={action.completed}
                      onChange={async e => {
                        console.dir(e.target.checked)

                        try {
                          await doUpdateSubTask({
                            variables: {
                              id: +action.id,
                              input: {
                                completed: e.target.checked
                              }
                            }
                          })
                        } catch (error) {
                          alert(error.message)
                        }
                      }}
                      inputProps={{
                        'aria-label': 'checkbox'
                      }}
                    />

                    <TextField fullWidth value={action.title} />
                  </Grid>
                </Grid>
              ))} */}
          {taskData?.task?.creator && (
            <div className='text-lg font-medium'>
              created by {taskData?.task?.creator?.name}
            </div>
          )}
          <Grid
            container
            item
            xs={12}
            md={12}
            spacing={1}
            justifyContent='space-between'
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                variant='contained'
                disabled={editLoading}
                onClick={duplicate}
              >
                {creating ? 'Create and Duplicate' : 'Save and Duplicate'}
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                type='submit'
                variant='contained'
                disabled={editLoading}
                fullWidth
                style={{
                  backgroundColor: 'green',
                  color: 'white',
                }}
              >
                {creating ? 'Create' : 'Update'}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant='contained'
                onClick={close}
                fullWidth
                style={{
                  backgroundColor: 'red',
                  color: 'white',
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    overflowX: 'auto',
  },
  formControl: {
    minWidth: 70,
  },
  form: {
    height: '100%',
  },
}))

export default EditTaskModal
