import firebase from 'firebase/app'
import 'firebase/messaging'

const config = {
  apiKey: 'AIzaSyBBIYQR_oGDtLCB6QC-j6HgTHTnt_hRl24',
  authDomain: 'bluisles-2d3ee.firebaseapp.com',
  databaseURL: 'https://bluisles-2d3ee.firebaseio.com',
  projectId: 'bluisles-2d3ee',
  storageBucket: 'bluisles-2d3ee.appspot.com',
  messagingSenderId: '436231349610',
  appId: '1:436231349610:web:c1f295bff7aa2a38557113',
  measurementId: 'G-Q0Q98CEW94',
}

firebase.initializeApp(config)
let messaging

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging()

  messaging.onMessage((payload) => {
    console.log(payload.notification)
    const notificationTitle = payload.notification.title
    const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon,
    }

    if (!('Notification' in window)) {
      console.log('This browser does not support system notifications.')
    } else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      var notification = new Notification(
        notificationTitle,
        notificationOptions
      )
      notification.onclick = function (event) {
        event.preventDefault()
        window.open(payload.notification.click_action, '_blank')
        notification.close()
      }
    }
  })

  // navigator.serviceWorker.getRegistrations().then(value => console.log(value))

  // if (firebase.messaging.isSupported()) {
  //   messaging.setBackgroundMessageHandler(function (payload) {
  //     return self.registration.showNotification(
  //       payload.title,
  //       payload.body,
  //       payload.data
  //     )
  //   })
  // }

  messaging.usePublicVapidKey(
    'BMuqrCf3ud-VIfNBxuI45g9czV0TidLeFYvcPSXp8QxiK2LDZMzHkk4WNmDKJm66Rh2RO_ofJf_Wo6yHXl6ndsM'
  )
}

export function getStartToken() {
  return new Promise((resolve, reject) => {
    if (firebase.messaging.isSupported()) {
      messaging
        .getToken()
        .then((currentToken) => {
          console.log(currentToken)
          resolve(currentToken)
          if (!currentToken) {
            //   sendTokenToServer(currentToken)
            // } else {
            //   // Show permission request.
            RequestPermission()
            //   setTokenSentToServer(false)
          }
        })
        .catch((err) => {
          reject(err)
          // setTokenSentToServer(false)
        })
    } else {
      reject('unsupported')
    }
  })
}

export function RequestPermission() {
  if (firebase.messaging.isSupported())
    messaging
      .requestPermission()
      .then(function (permission) {
        if (permission === 'granted') {
          console.log('Permission Granted')
          //calls method again and to sent token to server
          getStartToken()
        } else {
          console.log('Permission Denied')
        }
      })
      .catch(function (err) {
        console.log(err)
      })
}

export const requestFirebaseNotificationPermission = () => {
  if (firebase.messaging.isSupported()) {
    console.log('Test', messaging)
    messaging.requestPermission(() => {
      messaging
        .getToken()
        .then((firebaseToken) => {
          console.log(firebaseToken)
          return firebaseToken
        })
        .catch((err) => {
          return err
        })
    })

    onMessageListener()
  }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (firebase.messaging.isSupported())
      messaging.onMessage((payload) => {
        resolve(payload)
      })
  })
