import React, { useState, useEffect } from 'react'
import { useQuery, gql, useMutation } from '@apollo/client'
//import { Link } from 'react-router-dom'
import ResponsiveDrawer from '../components/drawer'
import { List, ListItem, ListItemText, Dialog, Button } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { pickBy, identity } from 'lodash'
import { DateTime } from 'luxon'
import EditTaskModal from './editTaskModal'
import { dateTimeFromServer, dateFromServer } from '../data/helpers'

const FAST_TRACK_PERSONS = gql`
  query persons($filter: FastTrackPersonFilter) {
    fastTrackPersons(filter: $filter) {
      id
      firstName
      lastName
      gender
      birthday
      passportNumber
      passportCountry
      localAddress
      email
      telephone
      fullyVaccinated
      highRisk
      fastTrack {
        id
        source
        insertedAt
        date
        request
        personCount
        airline
        deleted
        completed
        company
        companyRepresentative
        contactNumber
        contactEmail
        requestedService
        email
        telephone
        localAddress
        driverInfo
      }
    }
  }
`

const FAST_TRACK_UPDATE = gql`
  mutation updateFastTrack($input: UpdateFastTrackInput!, $id: ID!) {
    updateFastTrack(input: $input, id: $id) {
      id
      completed
      deleted
    }
  }
`

function requestedservice(service) {
  switch (service) {
    case 'ENTRY':
      return 'Entry (Arrival)'

    case 'DEPARTURE':
      return 'Departure'
    case 'DEPARTURE_LOUNGE':
      return 'Departure with Lounge Access'
    default:
      return service
  }
}

export const FastTracksPage = () => {
  const [filter, setFilter] = useState({})
  const [editTask, setEditTask] = useState(null)

  let { loading, error, data, refetch } = useQuery(FAST_TRACK_PERSONS, {
    variables: { filter: pickBy(filter, identity) },
  })

  data = data || { fastTrackPersons: [] }

  const [update] = useMutation(FAST_TRACK_UPDATE)

  useEffect(() => {
    refetch({ filter: pickBy(filter, identity) })
  }, [filter, refetch])

  return (
    <ResponsiveDrawer>
      <div className='p-2 bg-gray-100 h-screen'>
        <Paper>
          <List component='nav' aria-label='main users'>
            {loading && !data.fastTrackPersons.length && (
              <ListItem>
                <ListItemText primary={<p>loading...</p>} />
              </ListItem>
            )}

            {error && (
              <ListItem>
                <ListItemText primary={<p>{error.toString()}</p>} />
              </ListItem>
            )}

            <div className='flex flex-row  m-2 p-2 space-x-5'>
              <span>Filters:</span>
              <div className='space-x-1 flex items-center'>
                <input
                  type='checkbox'
                  id='completed'
                  name='completed'
                  onChange={(e) => {
                    setFilter({ ...filter, completed: !filter.completed })
                  }}
                  value={filter.completed}
                />

                <label for='completed'>Completed</label>
              </div>
              <div className='space-x-1 flex items-center'>
                <input
                  type='checkbox'
                  id='deleted'
                  name='deleted'
                  onChange={(e) => {
                    setFilter({ ...filter, deleted: !filter.deleted })
                  }}
                  value={filter.deleted}
                />

                <label for='deleted'>Deleted</label>
              </div>
            </div>

            <table className='w-full'>
              <tr className='border'>
                <th className='border transform rotate-45 w-2'></th>
                <th className='border transform rotate-45 w-2'>Completed</th>
                <th className='border transform rotate-45 w-2'>Deleted</th>

                <th className='border'>Date Created</th>
                <th className='border'>Order #</th>
                <th className='border'>Date</th>
                <th className='border'># Persons</th>
                <th className='border'>Airline & Flight #</th>

                <th className='border'>Requested Service</th>
                <th className='border'>Driver Info</th>
                {/*<th className='border'>Gender</th>*/}
                <th className='border'>First Name</th>
                <th className='border'>Last Name</th>
                <th className='border'>Date of Birth</th>
                <th className='border'>Age</th>
                {/*<th className='border'>Passport #</th>*/}
                {/*<th className='border'>Passport Country</th>*/}
                <th className='border'>Address in Barbados</th>
                <th className='border'>Email</th>
                <th className='border'>Telephone #</th>
                {/*<th className='border'>Fully Vaccinated</th>*/}
                {/*<th className='border'>Extra Quarantine</th>*/}
                <th className='border'>Special Request</th>
                <th className='border'>Company</th>
                <th className='border'>Company Representative</th>
                <th className='border'>Contact Number</th>
                <th className='border'>Contact Email</th>
              </tr>
              <tbody>
                {data.fastTrackPersons.map((track) => (
                  <tr>
                    <td className='border text-center'>
                      <Button
                        //component={Link}
                        //to={`/dashboard/tasks/new`}
                        onClick={() => {
                          let type

                          switch (track.fastTrack.requestedService) {
                            case 'DEPARTURE':
                            case 'DEPARTURE_LOUNGE':
                              type = 'DEPARTURE'
                              break
                            case 'ENTRY':
                              type = 'ARRIVAL'
                              break
                            default:
                              break
                          }

                          setEditTask({
                            type: type,
                            pickupLocation: 'BEE',
                            pickupLocationType: 'BEE',
                            features: ['BEE'],
                            flightNumber: track.fastTrack.airline,
                            passengerCount: track.fastTrack.personCount,
                            date: track.fastTrack.date,
                            title: `${track.firstName} ${track.lastName}`,
                            passengerContact: track.fastTrack.telephone,
                            passengerName: data.fastTrackPersons
                              .filter(
                                (t) => t.fastTrack.id === track.fastTrack.id
                              )
                              .map((t) => `${t.firstName} ${t.lastName}`)
                              .join(' & '),
                            note:
                              `${track.fastTrack.source}-${track.fastTrack.id}\n` +
                              `${dateFromServer(track.fastTrack.date)}\n\n` +
                              `Airline & Flight: ${track.fastTrack.airline}\n` +
                              `Contact: ${track.fastTrack.telephone}\n` +
                              `Address: ${track.fastTrack.localAddress}\n\n` +
                              `${data.fastTrackPersons
                                .filter(
                                  (t) => t.fastTrack.id === track.fastTrack.id
                                )
                                .map(
                                  (t) =>
                                    `Guest: ${t.firstName} ${t.lastName}\n` +
                                    `DOB: ${dateFromServer(t.birthday)}\n`
                                )
                                .join('\n')}` +
                              `\n${track.fastTrack.request}`,
                          })
                        }}
                        variant='outlined'
                        style={{
                          backgroundColor: 'green',
                          color: 'white',
                          width: '100%',
                        }}
                      >
                        Create Task
                      </Button>
                    </td>
                    <td className='border text-center'>
                      <input
                        type='checkbox'
                        name='completed'
                        onChange={async (e) => {
                          await update({
                            variables: {
                              id: track.fastTrack.id,
                              input: {
                                completed: e.target.checked,
                              },
                            },
                          })
                        }}
                        checked={track.fastTrack.completed}
                        value={track.fastTrack.completed}
                      />
                    </td>
                    <td className='border text-center'>
                      <input
                        type='checkbox'
                        name='deleted'
                        onChange={async (e) => {
                          await update({
                            variables: {
                              id: track.fastTrack.id,
                              input: {
                                deleted: e.target.checked,
                              },
                            },
                          })
                        }}
                        checked={track.fastTrack.deleted}
                        value={track.fastTrack.deleted}
                      />
                    </td>

                    <td className='border text-center'>
                      {dateTimeFromServer(track.fastTrack.insertedAt)}
                    </td>
                    <td className='border text-center'>
                      {`${track.fastTrack.source}-${track.fastTrack.id}`}
                    </td>
                    <td className='border text-center'>
                      {dateFromServer(track.fastTrack.date)}
                    </td>
                    <td className='border text-center'>
                      {track.fastTrack.personCount}
                    </td>
                    <td className='border text-center'>
                      {track.fastTrack.airline}
                    </td>
                    <td className='border text-center'>
                      {requestedservice(track.fastTrack.requestedService)}
                    </td>
                    <td className='border text-center'>
                      {track.fastTrack.driverInfo}
                    </td>
                    {/*<td className='border text-center'>{track.gender}</td>*/}
                    <td className='border text-center'>{track.firstName}</td>
                    <td className='border text-center'>{track.lastName}</td>
                    <td className='border text-center'>
                      {dateFromServer(track.birthday)}
                    </td>
                    <td className='border text-center'>
                      {Math.floor(
                        DateTime.local()
                          .diff(DateTime.fromISO(track.birthday), 'years')
                          .as('years')
                      )}
                    </td>
                    {/*<td className='border text-center'>
                      {track.passportNumber}
                    </td>*/}
                    {/*<td className='border text-center'>
                      {track.passportCountry}
                    </td>*/}
                    <td className='border text-center'>
                      {track.fastTrack.localAddress || track.localAddress}
                    </td>
                    <td className='border text-center'>
                      {track.fastTrack.email || track.email}
                    </td>
                    <td className='border text-center'>
                      {track.fastTrack.telephone || track.telephone}
                    </td>
                    {/*<td className='border text-center'>
                      {track.fullyVaccinated ? 'Yes' : 'No'}
                    </td>
                    <td
                      className={`border text-center ${
                        track.highRisk ? 'bg-red-600 text-white' : ''
                      }`}
                    >
                      {track.highRisk ? 'Yes' : 'No'}
                    </td>*/}
                    <td className='border text-center'>
                      {track.fastTrack.request}
                    </td>
                    <td className='border text-center'>
                      {track.fastTrack.company}
                    </td>
                    <td className='border text-center'>
                      {track.fastTrack.companyRepresentative}
                    </td>
                    <td className='border text-center'>
                      {track.fastTrack.contactNumber}
                    </td>
                    <td className='border text-center'>
                      {track.fastTrack.contactEmail}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </List>
        </Paper>
      </div>

      {!!editTask && (
        <Dialog
          maxWidth='md'
          //onBackdropClick={() => setEditTask(null)}
          onClose={(_event, reason) => {
            if (reason !== 'backdropClick') {
              setEditTask(null)
            }
          }}
          open={!!editTask}
        >
          {editTask && (
            <EditTaskModal task={editTask} close={() => setEditTask(null)} />
          )}
        </Dialog>
      )}
    </ResponsiveDrawer>
  )
}

export default FastTracksPage

//name,vaccine status, if tested, which test, release, notes, date, room number
