import React, { useState, useEffect } from 'react'
import { useQuery, gql, useMutation } from '@apollo/client'
import ResponsiveDrawer from '../components/drawer'
import { Dialog, Button } from '@material-ui/core'
//import Paper from '@material-ui/core/Paper'
import { pickBy, identity, uniq } from 'lodash'

import { reason, type } from '../common'
import EditTaskModal from './editTaskModal'

import { dateTimeFromServer, dateFromServer } from '../data/helpers'

const COVID_TEST_PERSONS = gql`
  query persons($filter: CovidTestPersonFilter) {
    covidTestPersons(filter: $filter) {
      id
      firstName
      lastName
      gender
      birthday
      passportNumber
      passportCountry
      localAddress
      email
      telephone
      fullyVaccinated
      highRisk
      covidTest {
        id
        insertedAt
        type
        source
        reason
        date
        personCount
        request
        eventDate
        appointmentTime
        completed
        deleted
        paid
        company
        companyRepresentative
        contactNumber
        contactEmail
      }
    }

    covidTestSource: __type(name: "CovidTestSource") {
      name
      values: enumValues {
        name
      }
    }
  }
`

const COVID_TEST_UPDATE = gql`
  mutation updateCovidTest($input: UpdateCovidTestInput!, $id: ID!) {
    updateCovidTest(input: $input, id: $id) {
      id
      completed
      deleted
      paid
    }
  }
`

//function toggleArrayItem(arr, item) {
//  return arr.includes(item)
//    ? arr.filter((i) => i !== item) // remove item
//    : [...arr, item] // add item
//}

export const CovidTestsPage = () => {
  const [filter, setFilter] = useState({})
  const [editTask, setEditTask] = useState(null)

  let { loading, error, data, refetch } = useQuery(COVID_TEST_PERSONS, {
    variables: { filter: pickBy(filter, identity) },
  })

  data = data || { covidTestPersons: [], covidTestSource: { values: [] } }

  const [update] = useMutation(COVID_TEST_UPDATE)

  let formatAddress = (data, user) => {
    let temp = data.covidTestPersons
      .filter((t) => t.covidTest.id === user.covidTest.id)
      .map((t) => t.localAddress)

    return uniq(temp).join(' & ')
  }

  let formatContact = (data, user) => {
    let temp = data.covidTestPersons
      .filter((t) => t.covidTest.id === user.covidTest.id)
      .map((t) => t.telephone)

    return uniq(temp).join(' / ')
  }

  useEffect(() => {
    refetch({ filter: pickBy(filter, identity) })
  }, [filter, refetch])

  //const updateFilter = (e) => {
  //  const { name, value } = e.target
  //  setFilter((previousFilter) => {
  //    return {
  //      ...previousFilter,
  //      [name]: value,
  //    }
  //  })
  //}

  return (
    <ResponsiveDrawer>
      {loading && !data.covidTestPersons.length && (
        <div className='flex items-center justify-center p-4 text-lg'>
          loading..
        </div>
      )}

      {error && (
        <div className='flex items-center justify-center p-4 text-lg'>
          {error.toString()}
        </div>
      )}

      <div className='flex flex-row  m-2 p-2 space-x-5'>
        <span>Filters:</span>
        <div className='space-x-1 flex items-center'>
          <input
            type='checkbox'
            id='completed'
            name='completed'
            onChange={(e) => {
              setFilter({ ...filter, completed: !filter.completed })
            }}
            value={filter.completed}
          />

          <label for='completed'>Completed</label>
        </div>
        <div className='space-x-1 flex items-center'>
          <input
            type='checkbox'
            id='deleted'
            name='deleted'
            onChange={(e) => {
              setFilter({ ...filter, deleted: !filter.deleted })
            }}
            value={filter.deleted}
          />

          <label for='deleted'>Deleted</label>
        </div>
        {/*{data.covidTestSource.values
          .filter(
            (source) => !['SANDALS', 'OCEANS', 'RCCL'].includes(source.name)
          )
          .map((source) => (
            <div className='space-x-1 flex items-center'>
              <input
                type='checkbox'
                id={source.name}
                name={source.name}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    source: toggleArrayItem(filter.source, source.name),
                  })
                }}
                value={source}
                checked={filter.source.includes(source.name)}
              />

              <label for={source.name}>{source.name}</label>
            </div>
          ))}*/}
      </div>

      <div className='w-full overflow-auto'>
        <table className='table-fixed bg-white'>
          <thead className='h-20'>
            <tr className='border'>
              <th className='border transform rotate-45 w-2'></th>
              <th className='border transform rotate-45 w-2'>Completed</th>
              <th className='border transform rotate-45 w-2'>Deleted</th>
              <th className='border'>Date Created</th>
              <th className='border'>Order #</th>
              <th className='border'>Date of Test</th>
              <th className='border'>Type of Test</th>
              <th className='border'>Reason for Test</th>
              <th className='border'># Persons</th>
              <th className='border'>Gender</th>
              <th className='border'>First Name</th>
              <th className='border'>Last Name</th>
              <th className='border'>Date of Birth</th>
              <th className='border'>Passport #</th>
              <th className='border'>Passport Country</th>
              <th className='border'>Address in Barbados</th>
              <th className='border'>Email</th>
              <th className='border'>Telephone #</th>
              <th className='border'>Fully Vaccinated</th>
              {/*<th className='border'>Extra Quarantine</th>*/}
              <th className='border'>Arrival / Departure / Exposure Date</th>
              <th className='border'>Special Request</th>
              <th className='border'>Company</th>
              <th className='border'>Company Representative</th>
              <th className='border'>Contact Number</th>
              <th className='border'>Contact Email</th>
            </tr>
          </thead>
          <tbody>
            {data.covidTestPersons.map((user) => (
              <tr key={user.id}>
                <td className='border text-center'>
                  <Button
                    onClick={() => {
                      setEditTask({
                        type: 'COVID_TEST',
                        pickupLocation: formatAddress(data, user),
                        pickupLocationType: 'COVID_TEST',
                        dropoffLocation: 'Lab',
                        features: [],
                        flightNumber: user.covidTest.type,
                        passengerCount: user.covidTest.personCount,
                        date: user.covidTest.date,
                        title: `${user.firstName} ${user.lastName}`,
                        passengerContact: formatContact(data, user),
                        passengerName: data.covidTestPersons
                          .filter((t) => t.covidTest.id === user.covidTest.id)
                          .map((t) => `${t.firstName} ${t.lastName}`)
                          .join(' & '),
                        note:
                          `${user.covidTest.source}-${
                            user.covidTest.id
                          } | ${reason(user.covidTest.reason)} \n\n` +
                          `Booked: ${dateTimeFromServer(
                            user.covidTest.insertedAt
                          )}\n` +
                          `For: ${dateFromServer(user.covidTest.date)}\n` +
                          `Arrival / Departure / Exposure Date: ${dateFromServer(
                            user.covidTest.eventDate
                          )} \n\n` +
                          `${user.covidTest.request}\n\n` +
                          `${data.covidTestPersons
                            .filter((t) => t.covidTest.id === user.covidTest.id)
                            .map(
                              (t) =>
                                `Guest: ${t.firstName} ${t.lastName}\n` +
                                `DOB: ${dateFromServer(t.birthday)}\n` +
                                `Gender: ${t.gender}\n` +
                                `Passport Number: ${t.passportNumber}\n` +
                                `Passport Country: ${t.passportCountry}\n` +
                                `Fully Vaccinated: ${
                                  t.fullyVaccinated ? 'Yes' : 'No'
                                }\n` +
                                `Email: ${t.email}\n` +
                                `Contact: ${t.telephone}\n` +
                                `Address: ${t.localAddress}\n`
                            )
                            .join('\n')}`,
                      })
                    }}
                    variant='outlined'
                    style={{
                      backgroundColor: 'green',
                      color: 'white',
                      width: '100%',
                    }}
                  >
                    Create Task
                  </Button>
                </td>
                <td className='border text-center'>
                  <input
                    type='checkbox'
                    name='completed'
                    onChange={async (e) => {
                      await update({
                        variables: {
                          id: user.covidTest.id,
                          input: {
                            completed: e.target.checked,
                          },
                        },
                      })
                    }}
                    checked={user.covidTest.completed}
                    value={user.covidTest.completed}
                  />
                </td>
                <td className='border text-center'>
                  <input
                    type='checkbox'
                    name='deleted'
                    onChange={async (e) => {
                      await update({
                        variables: {
                          id: user.covidTest.id,
                          input: {
                            deleted: e.target.checked,
                          },
                        },
                      })
                    }}
                    checked={user.covidTest.deleted}
                    value={user.covidTest.deleted}
                  />
                </td>
                <td className='border text-center'>
                  {dateTimeFromServer(user.covidTest.insertedAt)}
                </td>
                <td className='border text-center'>
                  {`${user.covidTest.source}-${user.covidTest.id}`}
                </td>
                <td className='border  text-center'>
                  {dateFromServer(user.covidTest.date)}
                </td>
                <td className='border  text-center'>
                  {type(user.covidTest.type)}
                </td>
                <td className='border  text-center'>
                  {reason(user.covidTest.reason)}
                </td>
                <td className='border text-center'>
                  {user.covidTest.personCount}
                </td>
                <td className='border text-center'>{user.gender}</td>
                <td className='border text-center'>{user.firstName}</td>
                <td className='border text-center'>{user.lastName}</td>
                <td className='border text-center'>
                  {dateFromServer(user.birthday)}
                </td>
                <td className='border text-center'>{user.passportNumber}</td>
                <td className='border text-center'>{user.passportCountry}</td>
                <td className='border text-center'>{user.localAddress}</td>
                <td className='border text-center'>{user.email}</td>
                <td className='border text-center'>{user.telephone}</td>
                <td className='border text-center'>
                  {user.fullyVaccinated ? 'Yes' : 'No'}
                </td>
                {/*<td
                  className={`border text-center ${
                    user.highRisk ? 'bg-red-600 text-white' : ''
                  }`}
                >
                  {user.highRisk ? 'Yes' : 'No'}
                </td>*/}
                <td className='border text-center'>
                  {dateFromServer(user.covidTest.eventDate)}
                </td>
                <td className='border text-center'>{user.covidTest.request}</td>
                <td className='border text-center'>{user.covidTest.company}</td>
                <td className='border text-center'>
                  {user.covidTest.companyRepresentative}
                </td>
                <td className='border text-center'>
                  {user.covidTest.contactNumber}
                </td>
                <td className='border text-center'>
                  {user.covidTest.contactEmail}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {!!editTask && (
        <Dialog
          maxWidth='md'
          //onBackdropClick={() => setEditTask(null)}
          onClose={(_event, reason) => {
            if (reason !== 'backdropClick') {
              setEditTask(null)
            }
          }}
          open={!!editTask}
        >
          {editTask && (
            <EditTaskModal task={editTask} close={() => setEditTask(null)} />
          )}
        </Dialog>
      )}
    </ResponsiveDrawer>
  )
}

export default CovidTestsPage

//name,vaccine status, if tested, which test, release, notes, date, room number
