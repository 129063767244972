import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, MenuItem } from '@material-ui/core'
import { useMutation, gql } from '@apollo/client'
import { DateTime, Settings } from 'luxon'
import logo from '../images/logo.png'
import bee from '../images/bee.jpg'
import {
  insertItem,
  removeItems,
  updateObjectInArray,
  TextField,
  DropdownField,
} from '../common'

import snackbar from '../components/snackbar'

Settings.defaultZoneName = 'America/Barbados'

const CREATE_FAST_TRACK = gql`
  mutation CreateFastTrack($input: CreateFastTrackInput!) {
    createFastTrack(input: $input) {
      id
    }
  }
`

let fieldClass = 'flex flex-col items-stretch sm:w-1/2 w-full p-2'

let dateFromField = (date) => {
  if (date) {
    let d = DateTime.fromFormat(date, 'yyyy-MM-dd', {
      zone: 'America/Barbados',
    })
    d.toISO()

    return d
  } else return null
}

//let startTime = DateTime.local({
//  zone: 'America/Barbados',
//})

let getTitle = (formType) => {
  switch (formType) {
    case 'NET_PACKAGE':
    case 'NET_PACKAGE_INVOICE':
      return 'Net Rate Priority Entry Package'

    case 'PACKAGE':
      return 'Blu Arrival Express'

    case 'INSPIRED':
      return 'Blu Expedited - Inspired'

    case 'REMAC':
      return 'Blu Expedited - Remac'

    case 'INVOICE':
    default:
      return 'Blu Expedited - Fast Track'
  }
}

//console.log(startTime.toString())

export const CreateFastTrackPage = ({ formType }) => {
  const history = useHistory()

  const [info, setInfo] = useState({
    personCount: 1,
    persons: [
      {
        id: 0,
      },
    ],
    source: formType || 'STORE',
  })

  let updateInfo = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value })
  }

  const [create, { loading: createLoading }] = useMutation(CREATE_FAST_TRACK)

  let save = async (e) => {
    e.preventDefault()

    if (info.date.includes('-12-25')) {
      snackbar.error('No Bookings on the 25th of December')

      return
    }

    try {
      await create({
        variables: {
          input: {
            ...info,
            source: formType || 'STORE',
            date: dateFromField(info.date),
            persons: info.persons.map((person) => {
              return {
                ...person,
                birthday: dateFromField(person.birthday),
              }
            }),
          },
        },
      })

      switch (formType) {
        case 'INVOICE':
          history.push(`/invoice/fasttrackform/complete`)
          break

        case 'NET_PACKAGE_INVOICE':
          history.push(`/net-package-invoice/fasttrackform/complete`)
          break

        case 'PACKAGE':
          window.location.href =
            'https://islandescapes.fygaroshops.com/products/6815f3ae-39c6-4c24-bc71-722af0a89ced/'
          break

        case 'NET_PACKAGE':
          switch (info.requested_service) {
            case 'ENTRY':
              window.location.href =
                'https://www.fygaro.com/en/payments/418e0beb-fe0c-4657-85a0-801e53b9ba13/buy-now'
              break

            case 'DEPARTURE':
              window.location.href =
                'https://www.fygaro.com/en/payments/391b7229-c360-430a-8831-b7a9b10c7cea/buy-now'
              break

            case 'DEPARTURE_LOUNGE':
              window.location.href =
                'https://www.fygaro.com/en/payments/63d0e118-4bc0-48bd-8814-95bbd08ca983/buy-now'
              break
            default:
              break
          }
          break
        default:
          switch (info.requested_service) {
            case 'ENTRY':
              window.location.href =
                'https://islandescapes.fygaroshops.com/products/bb2f966b-b5ca-42f3-b59b-ee0e3cc30448/'
              break

            case 'DEPARTURE':
              window.location.href =
                'https://www.fygaro.com/en/payments/78e133ce-32b2-4438-ae77-92351dbe48c6/buy-now'
              break

            case 'DEPARTURE_LOUNGE':
              window.location.href =
                'https://www.fygaro.com/en/payments/7a192dcd-1abb-417a-907d-c3ec3f508798/buy-now'
              break
            default:
              break
          }
      }
    } catch (error) {
      //alert(error.message)
    }
  }

  //  date
  //  person_count
  //  name
  //  airline
  //  flight_number

  return (
    <div className='flex h-screen w-screen flex-col items-center bg-gray-100'>
      <div className='p-4 flex flex-col justify-center items-center'>
        <img src={logo} alt='logo' />
        <img src={bee} alt='bee' className='h-24' />
      </div>
      <h1 className='text-2xl mb-2 text-gray-800'> {getTitle(formType)} </h1>
      <div className='h-full w-full flex justify-center  overflow-auto'>
        <form
          className='h-full sm:w-5/6 md:w-4/6 flex flex-row items-center flex-wrap content-start pb-2'
          onSubmit={save}
        >
          {/*<div className='w-full'> {JSON.stringify(info)} </div>*/}{' '}
          {/*<div className='w-full'> {dateFromField(info.date)?.toString()} </div>*/}
          {['INVOICE', 'NET_PACKAGE_INVOICE'].includes(formType) && (
            <div className='flex flex-row flex-wrap w-full bg-white rounded-lg shadow-lg  pb-4 items-end mb-4 overflow-hidden'>
              <div
                className='w-full text-center text-lg text-white font-medium p-1'
                style={{
                  backgroundColor: '#E98300',
                }}
              >
                Company Information{' '}
              </div>{' '}
              <TextField
                info={info}
                field='company'
                title='Company'
                classes={fieldClass}
                updateInfo={updateInfo}
                required
              />
              <TextField
                info={info}
                field='company_representative'
                title='Company Representative'
                classes={fieldClass}
                updateInfo={updateInfo}
                required
              />
              <TextField
                info={info}
                field='contact_number'
                title='Contact Number'
                classes={fieldClass}
                updateInfo={updateInfo}
                required
              />
              <TextField
                info={info}
                field='contact_email'
                title='Contact Email'
                type='email'
                classes={fieldClass}
                updateInfo={updateInfo}
                required
              />
            </div>
          )}
          <div className='flex flex-row flex-wrap w-full bg-white rounded-lg shadow-lg  pb-4 items-end mb-4 overflow-hidden'>
            <DropdownField
              required
              info={info}
              field='requested_service'
              title='Requested Service'
              classes={fieldClass}
              updateInfo={updateInfo}
              data={[
                <MenuItem value='ENTRY'>
                  Blu Expedited Entry(Arrival){' '}
                </MenuItem>,
                <MenuItem value='DEPARTURE'>
                  {' '}
                  Blu Expedited Departure{' '}
                </MenuItem>,
                <MenuItem value='DEPARTURE_LOUNGE'>
                  Blu Expedited Departure with Lounge Access{' '}
                </MenuItem>,
              ]}
            />
            <TextField
              info={info}
              field='date'
              title='Barbados - Arrival or Departure Date'
              type='date'
              classes={fieldClass}
              updateInfo={updateInfo}
              required
            />
            <DropdownField
              required
              info={info}
              field='personCount'
              title='Number of Persons'
              classes={fieldClass}
              updateInfo={(e) => {
                //updateInfo(e)

                let listy = removeItems(info.persons, +e.target.value)

                setInfo({
                  ...info,
                  personCount: +e.target.value,
                  persons: listy,
                })
              }}
              num={true}
              data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((lookup) => (
                <MenuItem value={lookup} key={lookup}>
                  {' '}
                  {lookup}{' '}
                </MenuItem>
              ))}
            />
            {/*<TextField
                          info={info}
                          field='name'
                          title='Name'
                          classes={fieldClass}
                          updateInfo={updateInfo}
                          required
                        />*/}{' '}
            <TextField
              info={info}
              field='airline'
              title='Airline and Flight Number (to Barbados)'
              classes={fieldClass}
              updateInfo={updateInfo}
              required
            />
            <TextField
              info={info}
              field='email'
              title='Email'
              classes={fieldClass}
              updateInfo={updateInfo}
              type='email'
              required
            />
            <TextField
              info={info}
              field='telephone'
              title='Telephone #'
              classes={fieldClass}
              updateInfo={updateInfo}
              required
            />
            <TextField
              info={info}
              field='localAddress'
              title='Address in Barbados (include room number if applicable)'
              classes={fieldClass}
              updateInfo={updateInfo}
              required
            />
            <DropdownField
              required
              info={info}
              field='driver_info'
              title='Driver info'
              classes={fieldClass}
              updateInfo={updateInfo}
              data={[
                <MenuItem value='BOOKED'> Booked with Blu Isles </MenuItem>,
                <MenuItem value='QUOTE'> Please Quote me </MenuItem>,
                <MenuItem value='PRIVATE'>
                  Private Transfers / Taxi Dispatch{' '}
                </MenuItem>,
              ]}
            />
            <TextField
              info={info}
              field='request'
              title='Special Requests / Other Details'
              classes={fieldClass}
              updateInfo={updateInfo}
            />
            {/*<TextField
                          info={info}
                          field='flight_number'
                          title='Flight Number'
                          classes={fieldClass}
                          updateInfo={updateInfo}
                          required
                        />*/}{' '}
          </div>
          {Array.from(Array(info.personCount).keys()).map((num) => {
            return (
              <CreatePerson
                key={num}
                index={num}
                setData={setInfo}
                data={info}
              />
            )
          })}
          <div className='flex flex-col  w-full mt-4 mb-4'>
            <Button
              type='submit'
              variant='contained'
              disabled={createLoading}
              fullWidth
              //onSubmit={save}
              style={{
                backgroundColor: 'green',
                color: 'white',
              }}
            >
              Submit{' '}
            </Button>{' '}
          </div>{' '}
        </form>{' '}
      </div>{' '}
    </div>
  )
}

export const CreatePerson = ({ index, update, data, setData }) => {
  const [info] = useState({
    id: index,
  })

  let updateInfo = (e) => {
    let item = data.persons.find((d) => +d.id === +index)
    if (item != null)
      setData({
        ...data,
        persons: updateObjectInArray(data.persons, {
          ...item,
          [e.target.name]: e.target.value,
        }),
      })
    else
      setData({
        ...data,
        persons: insertItem(data.persons, {
          id: index,
          [e.target.name]: e.target.value,
        }),
      })
  }

  return (
    <div className='w-full flex flex-col mt-4 bg-white rounded-lg overflow-hidden shadow-lg  pb-4'>
      {' '}
      {/*<div className='w-full border-black border-t border-opacity-20'></div>*/}{' '}
      <div
        className='w-full text-center text-lg text-white font-medium p-1'
        style={{
          backgroundColor: '#00B0CA',
        }}
      >
        Person# {index + 1}{' '}
      </div>{' '}
      <div className='w-full flex flex-row items-end flex-wrap'>
        <TextField
          info={info}
          field='firstName'
          title='First Name'
          classes={fieldClass}
          updateInfo={updateInfo}
          required
        />
        <TextField
          info={info}
          field='lastName'
          title='Last Name'
          classes={fieldClass}
          updateInfo={updateInfo}
          required
        />
        {/*<DropdownField
                      required
                      info={info}
                      field='gender'
                      title='Gender'
                      classes={fieldClass}
                      updateInfo={updateInfo}
                      data={[
                        <MenuItem value='MALE'>MALE</MenuItem>,
                        <MenuItem value='FEMALE'>FEMALE</MenuItem>,
                      ]}
                    />*/}
        <TextField
          info={info}
          field='birthday'
          title='Date of Birth'
          classes={fieldClass}
          updateInfo={updateInfo}
          type='date'
          required
        />
        {/*<TextField
                      info={info}
                      field='passportNumber'
                      title='Passport #'
                      classes={fieldClass}
                      updateInfo={updateInfo}
                      required
                    />*/}
        {/*<TextField
                      info={info}
                      field='passportCountry'
                      title='Passport Country'
                      classes={fieldClass}
                      updateInfo={updateInfo}
                      required
                    />*/}
        {/*<TextField
                      info={info}
                      field='localAddress'
                      title='Address in Barbados (include room number if applicable)'
                      classes={fieldClass}
                      updateInfo={updateInfo}
                      required
                    />*/}
        {/*<TextField
                      info={info}
                      field='email'
                      title='Email'
                      classes={fieldClass}
                      updateInfo={updateInfo}
                      type='email'
                      required
                    />*/}
        {/*<TextField
                      info={info}
                      field='telephone'
                      title='Telephone #'
                      classes={fieldClass}
                      updateInfo={updateInfo}
                      required
                    />*/}
        {/*<BooleanField
                      info={info}
                      field='fullyVaccinated'
                      title='Fully Vaccinated ?'
                      classes={fieldClass}
                      updateInfo={updateInfo}
                      required
                    />*/}
        {/*<BooleanField
                      info={info}
                      field='highRisk'
                      title='Recently travelled to Brazil, South Africa or India ?'
                      classes={fieldClass}
                      updateInfo={updateInfo}
                      required
                    />*/}{' '}
      </div>{' '}
    </div>
  )
}

export default CreateFastTrackPage
