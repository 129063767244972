import React from 'react'

export const PrivacyPolicyPage = () => {
  return (
    <div className='flex flex-col h-screen w-screen p-8'>
      <p className='font-bold self-center mb-8'>Privacy Policy</p>
      <p>
        At Island Escapes, we understand the importance of privacy and are
        committed to protecting the personal information of our users. This
        privacy policy outlines the types of information we collect and how it
        is used.
      </p>
      <p className='mt-8 font-semibold'>Information Collection and Use</p>
      <p>
        Island Escapes collects and uses personal information to operate and
        improve our app. This includes information such as:
      </p>
      <ul>
        <li>
          <p>
            Location data: Island Escapes collects location data in order to
            provide driving directions and to track the location of completed
            tasks.
          </p>
        </li>
        <li>
          <p>
            Contact information: Island Escapes collects contact information
            (such as phone number and email address) in order to communicate
            with users about their tasks and account.
          </p>
        </li>
        <li>
          <p>
            Task information: Island Escapes collects information about tasks,
            including details about the task and any notes provided by the user.
          </p>
        </li>
      </ul>
      <p>
        Island Escapes does not sell or share personal information with third
        parties, except as required by law.
      </p>
      <p className='mt-8 font-semibold'>Data Security</p>
      <p>
        Island Escapes takes reasonable precautions to protect personal
        information from loss, misuse, and unauthorized access, disclosure,
        alteration, and destruction. However, no internet or email transmission
        is ever fully secure or error free. In particular, email sent to or from
        Island Escapes may not be secure. Therefore, you should take special
        care in deciding what information you send to us via email.
      </p>
      <p className='mt-8 font-semibold'>Changes to this Privacy Policy</p>
      <p>
        Island Escapes may update this privacy policy from time to time. We
        encourage users to check this page frequently to stay informed about how
        we are protecting the personal information we collect. Your continued
        use of the Island Escapes app constitutes your agreement to this privacy
        policy and any updates.
      </p>
    </div>
  )
}

export default PrivacyPolicyPage
