import React, { useState } from 'react'
import { useQuery, gql, useSubscription, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Grid, makeStyles, IconButton, Chip, Checkbox } from '@material-ui/core'
// import GoogleMapReact from 'google-map-react'
import { locationColor, icon } from '../components/icons'
import { format, parseISO } from 'date-fns'
import { Close, PinDrop } from '@material-ui/icons'
import ReactMapGL, { Marker } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

// import ResponsiveDrawer from '../components/drawer'

const taskfields = `
  id
  title
  date
  type
  flightNumber
  pickupLocation
  pickupLocationType
  dropoffLocation
  passengerCount
  accepted
  features
  private
  internalNote

  driver {
    id
    name
  }

  creator {
    id
    name
  }

  subTasks {
    id
    title
    completed
  }

  actions {
    id
    type
    lat
    lng
    insertedAt

    user {
      id
      name
    }
  }
`

const TASK_SUBSCRIBE = gql`
  subscription task_subscription($id: Int!) {
    taskChanged(id: $id) {
      ${taskfields}
    }
  }
`
const TASK = gql`
  query task($id: Int!) {
    task(id: $id) {
      ${taskfields}
    }
  }
`

const DELETE_TASK_ACTION = gql`
  mutation DeleteTaskAction($id: Int!) {
    action: deleteTaskAction(id: $id) {
      id
    }
  }
`

const UPDATE_SUB_TASK = gql`
  mutation UpdateSubTask($id: Int!, $input: UpdateSubTaskInput!) {
    updateSubTask(id: $id, input: $input) {
      id
      title
      completed
    }
  }
`

export const TaskPage = ({ taskId }) => {
  const classes = useStyles()
  const { id: paramId } = useParams()
  let id = taskId || paramId
  let {
    loading: taskLoading,
    error: taskError,
    data: taskData,
  } = useQuery(TASK, { variables: { id: +id } })
  taskData = taskData || { task: {} }
  useSubscription(TASK_SUBSCRIBE, {
    variables: { id: +id },
  })

  const [doDeleteAction] = useMutation(DELETE_TASK_ACTION)
  const [doUpdateSubTask] = useMutation(UPDATE_SUB_TASK)

  const [viewport, setViewport] = useState({
    width: '100%',
    height: 300,
    longitude: -59.543198,
    latitude: 13.193887,
    zoom: 9,
  })

  if (taskLoading)
    return (
      <div className='h-32 w-96 flex items-center justify-center text-xl font-bold'>
        Loading...
      </div>
    )

  if (taskError)
    return (
      <div className='h-32 w-96 flex items-center justify-center'>
        {taskError.toString()}
      </div>
    )

  return (
    <Grid item className={classes.card} alignItems='stretch'>
      <Grid
        item
        style={{
          backgroundColor: locationColor(taskData.task.pickupLocationType),
          color: 'white',
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // fontSize: 20,
          height: 40,
        }}
      >
        {taskData.task.type}
      </Grid>
      <Grid container item className={classes.content} style={{ flex: 1 }}>
        <Grid item container justifyContent='space-between'>
          <Grid item style={{ fontWeight: 'bold' }}>
            {format(parseISO(taskData.task.date), 'h:mm a, dd EEEE, MMMM yyy')}
          </Grid>
          <Grid item>{taskData.task.flightNumber}</Grid>
          {!!taskData.task.passengerCount && (
            <Grid item>{taskData.task.passengerCount} pax</Grid>
          )}
        </Grid>

        {taskData.task.pickupLocation && taskData.task.dropoffLocation && (
          <Grid item xs={12} justifyContent='space-between' container>
            <Chip
              label={taskData.task.pickupLocation}
              style={{
                backgroundColor: locationColor(
                  taskData.task.pickupLocationType
                ),
                color: 'white',
                fontWeight: 'bold',
              }}
            />{' '}
            to <Chip label={taskData.task.dropoffLocation} />
          </Grid>
        )}

        <Grid item container justify='space-between'>
          <Grid item>{taskData.task.title}</Grid>

          <Grid item>
            {(taskData.task.features || []).map((feature) =>
              icon(feature, feature?.toString())
            )}
          </Grid>
        </Grid>
        <Grid item container>
          <Grid component='h4' item xs={12}>
            Sub Tasks
          </Grid>
          {!taskData.task.subTasks?.length && 'no subtasks'}
          {taskData.task.subTasks &&
            taskData.task.subTasks.map((action) => (
              <Grid
                key={action.id}
                item
                xs={12}
                justify='space-between'
                alignItems='center'
                container
              >
                <Grid item>
                  <Checkbox
                    size='small'
                    checked={action.completed}
                    onChange={async (e) => {
                      console.dir(e.target.checked)

                      try {
                        await doUpdateSubTask({
                          variables: {
                            id: +action.id,
                            input: {
                              completed: e.target.checked,
                            },
                          },
                        })
                      } catch (error) {
                        alert(error.message)
                      }
                    }}
                    inputProps={{
                      'aria-label': 'checkbox',
                    }}
                  />
                  {action.title}
                </Grid>
              </Grid>
            ))}
          {/* <Grid item xs={12}>
            <TextField label='Enter Sub task' fullWidth />{' '}
            <Button variant='contained'>Save</Button>
          </Grid> */}
        </Grid>

        <Grid item container>
          <Grid component='h4' item xs={12}>
            Actions
          </Grid>
          {!taskData.task.actions?.length && 'no actions'}

          {taskData.task.actions &&
            taskData.task.actions.map((action) => (
              <Grid
                item
                xs={12}
                justify='space-between'
                alignItems='center'
                container
              >
                <Grid item>
                  {format(parseISO(action.insertedAt), 'hh:mm a, dd MMM')} -{' '}
                  {action.type} - {action.user.name}{' '}
                  {action.lat && action.lng && (
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${action.lat},${action.lng}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <PinDrop />
                    </a>
                  )}
                </Grid>

                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  edge='start'
                  onClick={async () => {
                    try {
                      await doDeleteAction({
                        variables: {
                          id: +action.id,
                        },
                      })
                    } catch (error) {
                      alert(error.message)
                    }
                  }}
                  className={classes.menuButton}
                >
                  <Close />
                </IconButton>
              </Grid>
            ))}
        </Grid>

        {taskData.task.actions.length > 0 && (
          <Grid item container>
            <ReactMapGL
              {...viewport}
              onViewportChange={setViewport}
              mapStyle='mapbox://styles/mapbox/outdoors-v11'
              mapboxApiAccessToken='pk.eyJ1IjoiamFzb25hbHMiLCJhIjoiY2s3YzhyeWtiMGNnaDNkcHVuejNkZmgxMyJ9.8lx27pzsOntsIro_UGcCEQ'
            >
              {taskData.task.actions.map((action) => (
                <Marker
                  key={action.id}
                  latitude={+action.lat}
                  longitude={+action.lng}
                >
                  <div className={classes.markerPoint}></div>
                  <div className={classes.marker}></div>
                  <div className={classes.markerText}>
                    {action.type}
                    <br />
                    {format(parseISO(action.insertedAt), 'hh:mm a, dd MMM')}
                    <br />
                    {action.user.name}
                  </div>
                </Marker>
              ))}
            </ReactMapGL>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

// pk.eyJ1IjoiamFzb25hbHMiLCJhIjoiY2s3YzhwNHpvMDFsZzNkb3o3NjluempxNiJ9.ho8nkyEEKTNzxOlq3BnyJA

/* <Grid container style={{ height: 600 }}>
  <GoogleMapReact
    bootstrapURLKeys={{ key: 'AIzaSyBBIYQR_oGDtLCB6QC-j6HgTHTnt_hRl24' }}
    defaultCenter={{
      lng: -59.543198,
      lat: 13.193887
    }}
    defaultZoom={11}
  >
    {data?.task?.actions?.map(action => (
      <div
        key={action.id}
        className={classes.marker}
        lat={action.lat}
        lng={action.lng}
      >
        <div className={classes.markerPoint}></div>
        <div className={classes.markerText}>{action.type}</div>
      </div>
    ))}
  </GoogleMapReact>
</Grid> */

const useStyles = makeStyles((theme) => ({
  content: { padding: 8 },
  marker: {
    // initially any map object has left top corner at lat lng coordinates
    // it's on you to set object origin to 0,0 coordinates
    position: 'absolute',
    width: 40,
    height: 40,
    left: -40 / 2,
    top: -40 / 2,

    border: '2px solid #f44336',
    borderRadius: 40,

    textAlign: 'center',
    color: '#3f51b5',
    fontSize: 16,
    fontWeight: 'bold',
    padding: 4,
  },
  markerPoint: {
    position: 'absolute',
    width: 4,
    height: 4,
    left: -1,
    top: -1,
    border: '2px solid #f44336',
    borderRadius: 40,
  },
  markerText: {
    position: 'absolute',
    top: 21,
    left: -60,
    fontSize: 12,
    textAlign: 'center',
    border: '1px solid white',
    color: 'white',
    backgroundColor: 'black',
    padding: '0 6px',
    borderRadius: 10,
    width: 120,
  },
}))

export default TaskPage
