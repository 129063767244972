import React, { useState, useEffect } from 'react'
import { useQuery, gql, useMutation } from '@apollo/client'
//import { Link } from 'react-router-dom'
import ResponsiveDrawer from '../components/drawer'
import { List, ListItem, ListItemText, Dialog, Button } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { pickBy, identity } from 'lodash'
import EditTaskModal from './editTaskModal'
import { dateTimeFromServer, dateFromServer } from '../data/helpers'

const TRANSFERS_PERSONS = gql`
  query TransferPersons($filter: TransferPersonFilter) {
    transferPersons(filter: $filter) {
      id
      firstName
      lastName

      transfer {
        id
        date
        personCount
        request
        email
        type
        telephone
        localAddress
        completed
        deleted
        insertedAt
        flightNumber

        transferLocation {
          name
          url
          active
        }
      }
    }
  }
`

const TRANSFER_UPDATE = gql`
  mutation updateTransfer($input: UpdateTransferInput!, $id: ID!) {
    updateTransfer(input: $input, id: $id) {
      id
      completed
      deleted
    }
  }
`

export const TransfersPage = () => {
  const [filter, setFilter] = useState({})
  const [editTask, setEditTask] = useState(null)

  let { loading, error, data, refetch } = useQuery(TRANSFERS_PERSONS, {
    variables: { filter: pickBy(filter, identity) },
  })

  data = data || { transferPersons: [] }

  const [update] = useMutation(TRANSFER_UPDATE)

  useEffect(() => {
    refetch({ filter: pickBy(filter, identity) })
  }, [filter, refetch])

  return (
    <ResponsiveDrawer>
      <div className='p-2 bg-gray-100 h-screen'>
        <Paper>
          <List component='nav' aria-label='main users'>
            {loading && !data.transferPersons.length && (
              <ListItem>
                <ListItemText primary={<p>loading...</p>} />
              </ListItem>
            )}

            {error && (
              <ListItem>
                <ListItemText primary={<p>{error.toString()}</p>} />
              </ListItem>
            )}

            <div className='flex flex-row  m-2 p-2 space-x-5'>
              <span>Filters:</span>
              <div className='space-x-1 flex items-center'>
                <input
                  type='checkbox'
                  id='completed'
                  name='completed'
                  onChange={(e) => {
                    setFilter({ ...filter, completed: !filter.completed })
                  }}
                  value={filter.completed}
                />

                <label for='completed'>Completed</label>
              </div>
              <div className='space-x-1 flex items-center'>
                <input
                  type='checkbox'
                  id='deleted'
                  name='deleted'
                  onChange={(e) => {
                    setFilter({ ...filter, deleted: !filter.deleted })
                  }}
                  value={filter.deleted}
                />

                <label for='deleted'>Deleted</label>
              </div>
            </div>

            <table className='w-full'>
              <tr className='border'>
                <th className='border transform rotate-45 w-2'></th>
                <th className='border transform rotate-45 w-2'>Completed</th>
                <th className='border transform rotate-45 w-2'>Deleted</th>

                <th className='border'>Date Created</th>
                <th className='border'>Order #</th>
                <th className='border'>Type #</th>
                <th className='border'>Date</th>
                <th className='border'># Persons</th>

                <th className='border'>Transfer</th>
                <th className='border'>Airline & flight number</th>
                <th className='border'>First Name</th>
                <th className='border'>Last Name</th>
                <th className='border'>Hotel / Accommodation</th>
                <th className='border'>Email</th>
                <th className='border'>Telephone #</th>
                <th className='border'>Special Request</th>
              </tr>
              <tbody>
                {data.transferPersons.map((person) => (
                  <tr>
                    <td className='border text-center'>
                      <Button
                        onClick={() => {
                          setEditTask({
                            type: person.transfer.type,
                            pickupLocationType:
                              person.transfer.type === 'ARRIVAL'
                                ? 'AIRPORT'
                                : 'HOTEL',
                            passengerCount: person.transfer.personCount,
                            date: person.transfer.date,
                            //flightNumber: person.transfer.transferLocation.name,
                            title: `${person.firstName} ${person.lastName}`,
                            pickupLocation:
                              person.transfer.type === 'ARRIVAL'
                                ? 'Airport'
                                : person.transfer.localAddress,
                            dropoffLocation:
                              person.transfer.type === 'ARRIVAL'
                                ? person.transfer.localAddress
                                : 'Airport',
                            passengerContact: person.transfer.telephone,
                            passengerName: data.transferPersons
                              .filter(
                                (t) => t.transfer.id === person.transfer.id
                              )
                              .map((t) => `${t.firstName} ${t.lastName}`)
                              .join(' & '),
                            flightNumber: person.transfer.flightNumber,
                            note:
                              `#${person.transfer.id}\n` +
                              `${dateFromServer(person.transfer.date)}\n\n` +
                              `Transfer: ${person.transfer.transferLocation.name}\n` +
                              `Flight: ${person.transfer.flightNumber}\n` +
                              `Telephone: ${person.transfer.telephone}\n` +
                              `Email: ${person.transfer.email}\n\n` +
                              `${data.transferPersons
                                .filter(
                                  (t) => t.transfer.id === person.transfer.id
                                )
                                .map(
                                  (t) => `Guest: ${t.firstName} ${t.lastName}\n`
                                )
                                .join('\n')}` +
                              `\n${person.transfer.request}`,
                          })
                        }}
                        variant='outlined'
                        style={{
                          backgroundColor: 'green',
                          color: 'white',
                          width: '100%',
                        }}
                      >
                        Create Task
                      </Button>
                    </td>
                    <td className='border text-center'>
                      <input
                        type='checkbox'
                        name='completed'
                        onChange={async (e) => {
                          await update({
                            variables: {
                              id: person.transfer.id,
                              input: {
                                completed: e.target.checked,
                              },
                            },
                          })
                        }}
                        checked={person.transfer.completed}
                        value={person.transfer.completed}
                      />
                    </td>
                    <td className='border text-center'>
                      <input
                        type='checkbox'
                        name='deleted'
                        onChange={async (e) => {
                          await update({
                            variables: {
                              id: person.transfer.id,
                              input: {
                                deleted: e.target.checked,
                              },
                            },
                          })
                        }}
                        checked={person.transfer.deleted}
                        value={person.transfer.deleted}
                      />
                    </td>
                    <td className='border text-center'>
                      {dateTimeFromServer(person.transfer.insertedAt)}
                    </td>
                    <td className='border text-center'>{person.transfer.id}</td>
                    <td className='border text-center'>
                      {person.transfer.type}
                    </td>
                    <td className='border text-center'>
                      {dateFromServer(person.transfer.date)}
                    </td>
                    <td className='border text-center'>
                      {person.transfer.personCount}
                    </td>
                    <td className='border text-center'>
                      {person.transfer.transferLocation.name}
                    </td>
                    <td className='border text-center'>
                      {person.transfer.flightNumber}
                    </td>
                    <td className='border text-center'>{person.firstName}</td>
                    <td className='border text-center'>{person.lastName}</td>
                    <td className='border text-center'>
                      {person.transfer.localAddress}
                    </td>
                    <td className='border text-center'>
                      {person.transfer.email}
                    </td>
                    <td className='border text-center'>
                      {person.transfer.telephone}
                    </td>
                    <td className='border text-center'>
                      {person.transfer.request}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </List>
        </Paper>
      </div>

      {!!editTask && (
        <Dialog
          maxWidth='md'
          //onBackdropClick={() => setEditTask(null)}
          onClose={(_event, reason) => {
            if (reason !== 'backdropClick') {
              setEditTask(null)
            }
          }}
          open={!!editTask}
        >
          {editTask && (
            <EditTaskModal task={editTask} close={() => setEditTask(null)} />
          )}
        </Dialog>
      )}
    </ResponsiveDrawer>
  )
}

export default TransfersPage
