import { DateTime } from 'luxon'

export let dateTimeFromServer = (date) =>
  date &&
  DateTime.fromISO(date, {
    zone: 'America/Barbados',
  })
    .toFormat('DD, t')
    .toString()

export let dateFromServer = (date) =>
  date &&
  DateTime.fromISO(date, {
    zone: 'America/Barbados',
  })
    .toFormat('DD')
    .toString()
