import React from 'react'
import { Select, Input, MenuItem } from '@material-ui/core'

export let reason = (a) => {
  switch (a) {
    case 'TRAVEL':
      return 'Travel'
    case 'RELEASE':
      return 'Release from Quarantine'
    case 'CONTACT':
      return 'Contact Tracing'
    case 'GENERAL_KNOWLEDGE':
      return 'General Knowledge'

    default:
      return a
  }
}

export let dateEventLabel = (a) => {
  switch (a) {
    case 'TRAVEL':
      return 'Date of Departure'
    case 'RELEASE':
      return 'Date of Arrival'
    case 'CONTACT':
      return 'Date of Exposure'

    default:
      return 'Date of Departure / Arrival / Exposure'
  }
}

export let type = (a) => {
  switch (a) {
    case 'ANTIGEN':
      return 'Antigen'
    case 'RTPCR':
      return 'RT-PCR'
    case 'RAPIDPCR':
      return 'Rapid PCR'

    default:
      return a
  }
}

export function insertItem(array, data) {
  let newArray = array.slice()
  newArray.splice(data.id, 0, data)
  return newArray
}

export function removeItems(array, count) {
  return array.filter((item, index) => item.id < count)
}

export function removeItem(array, data) {
  return array.filter((item, index) => item.id !== data.id)
}

export function updateObjectInArray(array, data) {
  return array.map((item, index) => {
    if (index !== data.id) {
      return item
    }

    return {
      ...item,
      ...data,
    }
  })
}

export const TextField = ({
  info,
  field,
  title,
  classes,
  updateInfo,
  type,
  required,
}) => {
  return (
    <div className={classes}>
      <div className='font-semibold'>{title}</div>
      <Input
        size='large'
        label={title}
        name={field}
        type={type}
        value={info[field]}
        onChange={updateInfo}
        required={required}
      />
    </div>
  )
}

export const DropdownField = ({
  info,
  field,
  title,
  classes,
  updateInfo,
  data,
  num,
  required,
}) => {
  return (
    <div className={classes}>
      <div className='font-semibold'>{title}</div>
      <Select
        size='large'
        label={title}
        name={field}
        value={num ? +info[field] : info[field]}
        onChange={updateInfo}
        required={required}
      >
        {data}
      </Select>
    </div>
  )
}

export const BooleanField = ({
  info,
  field,
  title,
  classes,
  updateInfo,
  required,
}) => {
  return (
    <div className={classes}>
      <div className='font-semibold'>{title}</div>
      <Select
        size='large'
        label={title}
        name={field}
        value={info[field]}
        onChange={updateInfo}
        required={required}
      >
        <MenuItem value={true}>Yes</MenuItem>
        <MenuItem value={false}>No</MenuItem>
      </Select>
    </div>
  )
}
