import React from 'react'
// import ReactDOM from 'react-dom'
// import Icon from '@material-ui/core/Icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLongArrowAltRight,
  faExchangeAlt,
  faTint,
  faGem,
  faSuitcase,
  faEye,
  faCrown,
  faBolt,
  faBug,
  faWheelchair,
  faUserFriends,
  faThumbtack,
  faAnchor,
} from '@fortawesome/free-solid-svg-icons'

import toddler from '../images/toddler.png'
import baby from '../images/baby.png'
import towel from '../images/towel.png'

const IMG_SIZE = 20
const ICON_SIZE = 'lg'

export let icon = (name, key) => {
  return (
    <span
      key={key}
      style={{
        width: IMG_SIZE,
        height: IMG_SIZE,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 3,
      }}
      title={name}
    >
      {actualIcon(name)}
    </span>
  )
  //     return Tooltip(
  //       child: SizedBox(
  //         child: Center(child: actualIcon(name)),
  //         width: 24,
  //         height: 24,
  //       ),
  //       message: name,
  //     );
}

let actualIcon = (name) => {
  switch (name.toLowerCase()) {
    case 'note':
      // return <NoteOutlined fontSize='small' />
      return <FontAwesomeIcon icon={faThumbtack} size={ICON_SIZE} />

    case 'oneway':
      return <FontAwesomeIcon icon={faLongArrowAltRight} size={ICON_SIZE} />

    case 'rt':
      return (
        <FontAwesomeIcon icon={faExchangeAlt} size={ICON_SIZE} title={name} />
      )
    case 'bottlewater':
      return <FontAwesomeIcon icon={faTint} size={ICON_SIZE} />

    case 'luggage':
      return <FontAwesomeIcon icon={faSuitcase} size={ICON_SIZE} />

    case 'lux':
      return <FontAwesomeIcon icon={faGem} size={ICON_SIZE} />

    case 'shared':
      return <FontAwesomeIcon icon={faUserFriends} size={ICON_SIZE} />

    case 'exspace':
      return (
        <span
          style={{
            fontWeight: 'bold',
            fontSize: 18,
            position: 'relative',
            top: -2,
          }}
        >
          EX
        </span>
      )

    case 'redeye':
      return <FontAwesomeIcon icon={faEye} color='red' size={ICON_SIZE} />

    case 'limo':
      return <FontAwesomeIcon icon={faCrown} size={ICON_SIZE} />

    case 'bee':
      return <FontAwesomeIcon icon={faBolt} size={ICON_SIZE} />

    case 'assisted':
      return <FontAwesomeIcon icon={faWheelchair} size={ICON_SIZE} />

    case 'seaport':
      return <FontAwesomeIcon icon={faAnchor} size={ICON_SIZE} />

    case 'coldtowel':
      return <img src={towel} alt={name} width={IMG_SIZE} height={IMG_SIZE} />
    case 'booster':
      return <img src={toddler} alt={name} width={IMG_SIZE} height={IMG_SIZE} />
    case 'babyseat':
      return <img src={baby} alt={name} width={IMG_SIZE} height={IMG_SIZE} />

    default:
      return <FontAwesomeIcon icon={faBug} size={ICON_SIZE} />
  }
}

export let locationColor = (type) => {
  switch (type) {
    case 'AIRPORT':
      return '#5acacf'
    case 'ATTRACTION':
      return '#b69b27'
    case 'HOTEL':
      return '#FD7300'
    case 'PORT':
      return '#34b756'
    case 'BEE':
    case 'OTHER':
      return '#8a2890'
    case 'MISC':
      return '#000000'
    case 'TODO':
      return 'red'
    default:
      return 'grey'
  }
}
