import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  split,
} from '@apollo/client'
import { uniqBy } from 'lodash'

import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import { hasSubscription } from '@jumpn/utils-graphql'

import session from './session'
import snackbar from '../components/snackbar'

const graphQLWsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_SUBSCRIPTION_HOST,
    connectionParams: () => {
      const ses = session.getToken()
      if (!ses) return {}
      return { Authorization: `Bearer ${ses}` }
    },
  })
)

const link = split(
  (operation) => hasSubscription(operation.query),
  graphQLWsLink,
  new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_HOST,
  })
)

const authLink = setContext((_, { headers }) => {
  const token = session.getToken()

  if (!token) return { headers }

  return {
    headers: {
      ...headers,
      'Access-Control-Allow-Origin': '*',
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const error = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      if (message === 'unauthorized') {
        // client.clearStore()
        localStorage.clear()
        return null
      }
      snackbar.error(message)
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
      return null
    })
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        taskList: {
          keyArgs: ['filter'],
          merge(existing, incoming, { readField }) {
            if (!incoming) return existing
            if (!existing) return incoming

            const { entries, ...rest } = incoming

            rest.entries = uniqBy([...existing.entries, ...entries], '__ref')

            return rest
          },
        },
      },
    },
  },
})
// const cache = new InMemoryCache({
//   typePolicies: {
//     Query: {
//       fields: {
//         task: {
//           read (existing, { args, toReference }) {
//             return (
//               existing ||
//               toReference({
//                 __typename: 'Task',
//                 ...args
//               })
//             )
//           }
//         }
//       }
//     }
//   }
// })

// persistCache({
//   cache,
//   storage: window.localStorage
// })

export const client = new ApolloClient({
  cache: cache,
  link: ApolloLink.from([error, authLink.concat(link)]),
})

// const offsetLimitPaginatedField = () => {
//   return {
//     keyArgs: false,
//     merge (existing, incoming, { args }) {
//       const merged = existing ? existing.slice(0) : []
//       for (let i = args.offset; i < args.offset + args.limit; ++i) {
//         merged[i] = incoming[i - args.offset]
//       }
//       return merged
//     },
//     read (existing, { args }) {
//       return existing && existing.slice(args.offset, args.offset + args.limit)
//     }
//   }
// }
