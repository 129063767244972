import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, MenuItem } from '@material-ui/core'
import { useMutation, useQuery, gql } from '@apollo/client'
import { DateTime } from 'luxon'
import logo from '../images/logo.png'
import {
  type,
  reason,
  insertItem,
  removeItems,
  updateObjectInArray,
  TextField,
  DropdownField,
  BooleanField,
  dateEventLabel,
} from '../common'
import snackbar from '../components/snackbar'

const LOOKUPS = gql`
  query Lookups {
    covidTestType: __type(name: "CovidTestType") {
      name
      values: enumValues {
        name
      }
    }
    covidTestReason: __type(name: "CovidTestReason") {
      name
      values: enumValues {
        name
      }
    }
    covidTestSource: __type(name: "CovidTestSource") {
      name
      values: enumValues {
        name
      }
    }
  }
`

const CREATE_COVID_TEST = gql`
  mutation createCovidTest($input: CreateCovidTestInput!) {
    createCovidTest(input: $input) {
      id
    }
  }
`

let fieldClass = 'flex flex-col items-stretch sm:w-1/2 w-full p-2'

let dateFromField = (date) =>
  date &&
  DateTime.fromFormat(date, 'yyyy-MM-dd', {
    zone: 'America/Barbados',
  }).toISO()

export const CreateCovidPage = ({ formType }) => {
  const history = useHistory()

  const [info, setInfo] = useState({
    personCount: 1,
    source: formType || 'STORE',
    persons: [{ id: 0 }],
  })

  let updateInfo = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value })
  }

  let { data: lookups, loading: lookupsLoading } = useQuery(LOOKUPS)

  const [create, { loading: createLoading }] = useMutation(CREATE_COVID_TEST)

  let save = async (e) => {
    e.preventDefault()

    if (info.date.includes('-12-25')) {
      snackbar.error('No Bookings on the 25th of December')

      return
    }

    try {
      await create({
        variables: {
          input: {
            ...info,
            source: formType || 'STORE',
            date: dateFromField(info.date),
            eventDate: dateFromField(info.eventDate),
            persons: info.persons.map((person) => {
              return {
                ...person,
                birthday: dateFromField(person.birthday),
              }
            }),
          },
        },
      })

      switch (formType) {
        case 'INVOICE':
          history.push(`/invoice/covidtestform/complete`)

          break

        default:
          if (info.type === 'ANTIGEN')
            window.location.href =
              'https://islandescapes.fygaroshops.com/products/2202a172-0106-4276-ac15-aa29515ec5f3/'
          else
            window.location.href =
              'https://islandescapes.fygaroshops.com/products/00de24f6-fb72-4c22-b591-aca73d8ca8a0/'
          break
      }
    } catch (error) {
      //alert(error.message)
    }
  }

  return (
    <div className='flex h-screen w-screen flex-col items-center bg-gray-100'>
      <div className='p-4'>
        <img src={logo} alt='logo' />
      </div>

      <h1 className='text-2xl mb-2 text-gray-800'>COVID-19 Testing</h1>

      <div
        className='h-full w\
      -full flex justify-center  overflow-auto'
      >
        <form
          className='h-full sm:w-5/6 md:w-4/6 flex flex-row items-center flex-wrap content-start mb-2'
          onSubmit={save}
        >
          {/*<div className='w-full'> {JSON.stringify(info)} </div>*/}
          {formType === 'INVOICE' && (
            <div className='flex flex-row flex-wrap w-full bg-white rounded-lg shadow-lg  pb-4 items-end mb-4 overflow-hidden'>
              <div
                className='w-full text-center text-lg text-white font-medium p-1'
                style={{ backgroundColor: '#E98300' }}
              >
                Company Information
              </div>
              <TextField
                info={info}
                field='company'
                title='Company'
                classes={fieldClass}
                updateInfo={updateInfo}
                required
              />
              <TextField
                info={info}
                field='company_representative'
                title='Company Representative'
                classes={fieldClass}
                updateInfo={updateInfo}
                required
              />
              <TextField
                info={info}
                field='contact_number'
                title='Contact Number'
                classes={fieldClass}
                updateInfo={updateInfo}
                required
              />
              <TextField
                info={info}
                field='contact_email'
                title='Contact Email'
                type='email'
                classes={fieldClass}
                updateInfo={updateInfo}
                required
              />
            </div>
          )}

          <div className='flex flex-row flex-wrap w-full bg-white rounded-lg shadow-lg  pb-4 items-end'>
            <TextField
              info={info}
              field='date'
              title='Date of Test'
              classes={fieldClass}
              updateInfo={updateInfo}
              type='date'
              required
            />

            <DropdownField
              required
              info={info}
              field='type'
              title='Type of Test'
              classes={fieldClass}
              updateInfo={updateInfo}
              data={
                !lookupsLoading
                  ? lookups.covidTestType.values
                      .filter((lookup) => lookup.name !== 'RAPIDPCR')
                      .map((lookup) => (
                        <MenuItem value={lookup.name} key={lookup.name}>
                          {type(lookup.name)}
                        </MenuItem>
                      ))
                  : []
              }
            />

            <DropdownField
              required
              info={info}
              field='reason'
              title='Reason for Test'
              classes={fieldClass}
              updateInfo={updateInfo}
              data={
                !lookupsLoading
                  ? lookups.covidTestReason.values.map((lookup) => (
                      <MenuItem value={lookup.name} key={lookup.name}>
                        {reason(lookup.name)}
                      </MenuItem>
                    ))
                  : []
              }
            />

            <DropdownField
              required
              info={info}
              field='personCount'
              title='Number of Persons'
              classes={fieldClass}
              updateInfo={(e) => {
                //updateInfo(e)

                let listy = removeItems(info.persons, +e.target.value)

                setInfo({
                  ...info,
                  personCount: +e.target.value,
                  persons: listy,
                })
              }}
              num={true}
              data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((lookup) => (
                <MenuItem value={lookup} key={lookup}>
                  {lookup}
                </MenuItem>
              ))}
            />

            {info.reason !== 'GENERAL_KNOWLEDGE' && (
              <TextField
                info={info}
                field='eventDate'
                title={`${dateEventLabel(info.reason)}`}
                classes={fieldClass}
                updateInfo={updateInfo}
                type='date'
                required
              />
            )}

            <TextField
              info={info}
              field='request'
              title='Special Requests / Other Details'
              classes={fieldClass}
              updateInfo={updateInfo}
            />
          </div>

          {Array.from(Array(info.personCount).keys()).map((num) => {
            return (
              <CreatePerson
                key={num}
                index={num}
                setData={setInfo}
                data={info}
              />
            )
          })}
          <div className='flex flex-col  w-full mt-4 mb-4'>
            <Button
              type='submit'
              variant='contained'
              disabled={createLoading}
              fullWidth
              //onSubmit={save}
              style={{
                backgroundColor: 'green',
                color: 'white',
              }}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export const CreatePerson = ({ index, update, data, setData }) => {
  const [info] = useState({ id: index })

  let updateInfo = (e) => {
    let item = data.persons.find((d) => +d.id === +index)
    if (item)
      setData({
        ...data,
        persons: updateObjectInArray(data.persons, {
          ...item,
          [e.target.name]: e.target.value,
        }),
      })
    else
      setData({
        ...data,
        persons: insertItem(data.persons, {
          id: index,
          [e.target.name]: e.target.value,
        }),
      })
  }

  return (
    <div className='w-full flex flex-col mt-4 bg-white rounded-lg overflow-hidden shadow-lg  pb-4'>
      {/*<div className='w-full border-black border-t border-opacity-20'></div>*/}
      <div
        className='w-full text-center text-lg text-white font-medium p-1'
        style={{ backgroundColor: '#00B0CA' }}
      >
        Person #{index + 1}
      </div>
      <div className='w-full flex flex-row items-end flex-wrap'>
        <TextField
          info={info}
          field='firstName'
          title='First Name'
          classes={fieldClass}
          updateInfo={updateInfo}
          required
        />

        <TextField
          info={info}
          field='lastName'
          title='Last Name'
          classes={fieldClass}
          updateInfo={updateInfo}
          required
        />

        <DropdownField
          required
          info={info}
          field='gender'
          title='Gender'
          classes={fieldClass}
          updateInfo={updateInfo}
          data={[
            <MenuItem value='MALE'>MALE</MenuItem>,
            <MenuItem value='FEMALE'>FEMALE</MenuItem>,
          ]}
        />

        <TextField
          info={info}
          field='birthday'
          title='Date of Birth'
          classes={fieldClass}
          updateInfo={updateInfo}
          type='date'
          required
        />

        <TextField
          info={info}
          field='passportNumber'
          title='Passport #'
          classes={fieldClass}
          updateInfo={updateInfo}
          required
        />

        <TextField
          info={info}
          field='passportCountry'
          title='Passport Country'
          classes={fieldClass}
          updateInfo={updateInfo}
          required
        />

        <TextField
          info={info}
          field='localAddress'
          title='Address in Barbados (include room number if applicable)'
          classes={fieldClass}
          updateInfo={updateInfo}
          required
        />

        <TextField
          info={info}
          type='email'
          field='email'
          title='Email'
          classes={fieldClass}
          updateInfo={updateInfo}
          required
        />

        <TextField
          info={info}
          field='telephone'
          title='Telephone #'
          classes={fieldClass}
          updateInfo={updateInfo}
          required
        />

        <BooleanField
          info={info}
          field='fullyVaccinated'
          title='Fully Vaccinated ?'
          classes={fieldClass}
          updateInfo={updateInfo}
          required
        />

        {/*<BooleanField
          info={info}
          field='highRisk'
          title='Recently travelled to Brazil, South Africa or India ?'
          classes={fieldClass}
          updateInfo={updateInfo}
          required
        />*/}
      </div>
    </div>
  )
}

export default CreateCovidPage
