import React from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import GA from 'react-ga'

import session from './data/session'

import ResponsiveDrawer from './components/drawer'

import LoginPage from './pages/login'
import UsersPage from './pages/users'
import UserPage from './pages/user'
import EditUserPage from './pages/editUser'
import TasksPage from './pages/tasks'
import TaskPage from './pages/task'
import EditTaskPage from './pages/editTask'
import InvoicesPage from './pages/invoices'
import EditInvoicePage from './pages/editInvoice'
import CreateCovidPage from './pages/covidTestForm'
import CreateCovidHotelPage from './pages/covidTestFormHotel'
import CreateCovidHotelComplePage from './pages/covidTestFormHotelComplete'
import CovidTestsPage from './pages/covidTests'
import CreateFastTrackPage from './pages/fastTrackForm'
import FastTracksPage from './pages/fastTracks'
import FastTrackFormCompletePage from './pages/fastTrackFormComplete'
import FastTrackNetPackageInvoiceFormComplete from './pages/fastTrackNetPackageInvoiceFormComplete'
import AttractionsPage from './pages/attractions'
import TransfersPage from './pages/transfers'
import CreateAttractionPage from './pages/AttractionForm'
import CreateTransferForm from './pages/TransferForm'

import { Settings } from 'luxon'
import { PrivacyPolicyPage } from './pages/privacyPolicy'

Settings.defaultZoneName = 'America/Barbados'

export default function Routes() {
  let location = useLocation()
  React.useEffect(() => {
    GA.set({ page: location.pathname })
    GA.pageview(location.pathname)
  }, [location])

  return (
    <Switch>
      <PublicRoute exact path='/'>
        <LoginPage />
      </PublicRoute>

      <Route exact path='/privacy-policy'>
        <PrivacyPolicyPage />
      </Route>

      {/*<Route exact path='/RSSC'>
        <CreateCovidHotelPage name='RSSC' />
      </Route>

      <Route exact path='/EUROFINS'>
        <CreateCovidHotelPage name='EUROFINS' />
      </Route>*/}
      <Route exact path='/fasttrackform'>
        <CreateFastTrackPage formType='STORE' />
      </Route>
      <Route exact path='/invoice/fasttrackform'>
        <CreateFastTrackPage formType='INVOICE' />
      </Route>
      <Route exact path='/inspired/fasttrackform'>
        <CreateFastTrackPage formType='INSPIRED' />
      </Route>
      <Route exact path='/remac/fasttrackform'>
        <CreateFastTrackPage formType='REMAC' />
      </Route>
      <Route exact path='/package/fasttrackform'>
        <CreateFastTrackPage formType='PACKAGE' />
      </Route>
      <Route exact path='/net-package/fasttrackform'>
        <CreateFastTrackPage formType='NET_PACKAGE' />
      </Route>
      <Route exact path='/net-package-invoice/fasttrackform'>
        <CreateFastTrackPage formType='NET_PACKAGE_INVOICE' />
      </Route>
      <Route exact path='/net-package-invoice/fasttrackform/complete'>
        <FastTrackNetPackageInvoiceFormComplete />
      </Route>
      <Route exact path='/invoice/fasttrackform/complete'>
        <FastTrackFormCompletePage />
      </Route>
      <Route exact path='/covidtestform'>
        <CreateCovidPage />
      </Route>
      <Route exact path='/attractionform'>
        <CreateAttractionPage />
      </Route>
      <Route exact path='/transferform'>
        <CreateTransferForm />
      </Route>
      <Route exact path='/invoice/covidtestform'>
        <CreateCovidPage formType='INVOICE' />
      </Route>
      <Route exact path='/:name/covidtestform'>
        <CreateCovidHotelPage />
      </Route>
      <Route exact path='/:name/covidtestform/complete'>
        <CreateCovidHotelComplePage />
      </Route>
      <PrivateRoute exact path='/dashboard'>
        <ResponsiveDrawer />
      </PrivateRoute>
      <PrivateRoute path='/dashboard/tasks/new'>
        <EditTaskPage />
      </PrivateRoute>
      <PrivateRoute path='/dashboard/tasks/:id/edit'>
        <EditTaskPage />
      </PrivateRoute>
      <PrivateRoute path='/dashboard/tasks/:id'>
        <TaskPage />
      </PrivateRoute>
      <PrivateRoute path='/dashboard/tasks'>
        <TasksPage />
      </PrivateRoute>
      <PrivateRoute path='/dashboard/users/new'>
        <EditUserPage />
      </PrivateRoute>
      <PrivateRoute path='/dashboard/users/:id/edit'>
        <EditUserPage />
      </PrivateRoute>
      <PrivateRoute path='/dashboard/users/:id'>
        <UserPage />
      </PrivateRoute>
      <PrivateRoute path='/dashboard/users'>
        <UsersPage />
      </PrivateRoute>
      <PrivateRoute path='/dashboard/invoices/new'>
        <EditInvoicePage />
      </PrivateRoute>
      <PrivateRoute path='/dashboard/invoices/:id/edit'>
        <EditInvoicePage />
      </PrivateRoute>
      <PrivateRoute path='/dashboard/invoices'>
        <InvoicesPage />
      </PrivateRoute>
      <PrivateRoute path='/dashboard/forms/covidtest'>
        <CovidTestsPage />
      </PrivateRoute>
      <PrivateRoute path='/dashboard/forms/fasttrack'>
        <FastTracksPage />
      </PrivateRoute>
      <PrivateRoute path='/dashboard/forms/attraction'>
        <AttractionsPage />
      </PrivateRoute>
      <PrivateRoute path='/dashboard/forms/transfer'>
        <TransfersPage />
      </PrivateRoute>
    </Switch>
  )
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (session.isAuthenticated()) return children

        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}
function PublicRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!session.isAuthenticated()) return children

        return (
          <Redirect
            to={{
              pathname: '/dashboard/tasks',
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}
