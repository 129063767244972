import React from 'react'

const Loading = ({ fit }) => (
  <div
    className={`${
      fit ? 'h-full' : 'h-screen'
    } w-full flex items-center justify-center text-xl font-bold sm:bg-gray-100`}
  >
    Loading...
  </div>
)

export default Loading
