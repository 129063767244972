import React from 'react'
import ReactDOM from 'react-dom'
import { SnackbarProvider, withSnackbar } from 'notistack'

let snackbar = {
  success: function (msg) {
    this.toast(msg, 'success')
  },
  error: function (msg) {
    this.toast(msg, 'error')
  },
  toast: function (msg, variant) {
    const Display = withSnackbar(({ message, enqueueSnackbar }) => {
      enqueueSnackbar(message, { variant })
      return null
    })
    const mountPoint = document.getElementById('snackbar')
    ReactDOM.render(
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Display message={msg} variant={variant} />
      </SnackbarProvider>,
      mountPoint
    )
  },
}

export default snackbar
